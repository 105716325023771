import { Box, InputLabel, SelectProps, createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ErrorMessage, Field } from "formik";
import React from "react";
import CustomSelect from "./CustomSelect";
import CustomTypography from "./CustomTypography.web";

export interface Props extends WithStyles {
  subLabel?: string;
}

export type CustomFormSelectFieldProps = SelectProps & Props;

const styles = createStyles({
  container: {
    "& + &": {
      marginTop: 24,
    },

    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#DC2626",
      fontWeight: 400,
      lineHeight: "18px",
      fontSize: 12,
      fontFamily: "Inter",
      textAlign: "initial",
    },
  },

  label: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "22px",
    color: "#333333",
    fontFamily: "Raleway",
  },

  subLabel: {
    display: "inline-block",
    fontSize: "12px !important",
    marginLeft: 8,
  },
});

export class CustomFormSelectField extends React.Component<CustomFormSelectFieldProps> {
  constructor(props: CustomFormSelectFieldProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { name, label, classes, error, subLabel, ...rest } = this.props;

    return (
      <Box className={classes?.container}>
        <InputLabel className={classes.label} htmlFor={name}>
          {label}

          {subLabel && (
            <CustomTypography className={classes.subLabel}>
              {subLabel}
            </CustomTypography>
          )}
        </InputLabel>

        <Field
          className={classes.input}
          as={CustomSelect}
          name={name}
          error={error}
          helperText={error && <ErrorMessage name={name} />}
          {...rest}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(CustomFormSelectField);
