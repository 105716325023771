import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  WithStyles,
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomButton from "../../../components/src/CustomButton.web";
import KitchenMenuChoiceGroupAccordion, {
  KitchenMenuChoiceGroup,
} from "../../../components/src/KitchenMenuChoiceGroupAccordion.web";
import KitchenMenuManagementEmpty from "./KitchenMenuManagementEmpty.web";
import KitchenMenuChoiceGroupFormDrawer, {
  KitchenMenuChoiceGroupFormDrawerProps,
} from "../../../components/src/KitchenMenuChoiceGroupFormDrawer.web";
import { WithTranslation, withTranslation } from "react-i18next";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "&.rtl": {
        direction: "rtl",
      },
    },

    top: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "center",
      },
    },

    title: {
      fontSize: "22px !important",
      lineHeight: "25.83px !important",
    },

    description: {
      marginTop: 8,
    },

    createButton: {
      [theme.breakpoints.down("xs")]: {
        marginTop: 15,
      },

      [theme.breakpoints.up("sm")]: {
        marginLeft: "auto",
      },

      "&.rtl": {
        [theme.breakpoints.up("sm")]: {
          marginLeft: "initial",
          marginRight: "auto",
        },
      },
    },

    choiceGroup: {
      marginTop: 37,
    },

    choiceGroupDataItem: {
      "& + &": {
        marginTop: 15,
      },
    },
  });

export interface Props extends WithStyles, WithTranslation {
  choiceGroups: KitchenMenuChoiceGroup[];
  choiceGroupDrawer: KitchenMenuChoiceGroupFormDrawerProps;

  openChoiceGroupDrawer(): void;
  closeChoiceGroupDrawer(): void;
  createChoiceGroup(values: {
    title: string;
    image: File;
    subCategories: string[];
  }): void;
  openEditChoiceGroupDrawer(id: string): void;
  editChoiceGroup(values: {
    title: string;
    image: File;
    subCategories: string[];
  }): void;
}
// Customizable Area End

export class KitchenMenuManagementChoiceGroup extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      classes,
      choiceGroups,
      choiceGroupDrawer,
      t,
      i18n,

      closeChoiceGroupDrawer,
      openChoiceGroupDrawer,
      createChoiceGroup,
      editChoiceGroup,
      openEditChoiceGroupDrawer,
    } = this.props;

    const rtl = i18n.language === "ar";

    return (
      <Box className={clsx(classes.container, { rtl })}>
        <Box className={classes.top}>
          <Box className={classes.heading}>
            <CustomTypography className={classes.title} variant="h2">
              {t("Choice Group")}
            </CustomTypography>

            <CustomTypography className={classes.description} variant="body1">
              {t(
                "This is where you'll find the groups of choices your customers can add to their items"
              )}
            </CustomTypography>
          </Box>

          <CustomButton
            data-test-id="create-choice-group-btn"
            className={clsx(classes.createButton, { rtl })}
            color="primary"
            variant="contained"
            onClick={openChoiceGroupDrawer}
          >
            {t("Create Choice Group")}
          </CustomButton>
        </Box>

        <Box className={classes.choiceGroup}>
          {choiceGroups.length ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {choiceGroups.map(
                  (choiceGroup, index) =>
                    (index + 1) % 2 !== 0 && (
                      <Box
                        className={classes.choiceGroupDataItem}
                        key={choiceGroup.id}
                      >
                        <KitchenMenuChoiceGroupAccordion
                          data-test-id={`kitchen-menu-choice-group-accordion-${choiceGroup.id}`}
                          choiceGroup={choiceGroup}
                          openEditChoiceGroupDrawer={openEditChoiceGroupDrawer}
                        />
                      </Box>
                    )
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {choiceGroups.map(
                  (choiceGroup, index) =>
                    (index + 1) % 2 === 0 && (
                      <Box
                        className={classes.choiceGroupDataItem}
                        key={choiceGroup.id}
                      >
                        <KitchenMenuChoiceGroupAccordion
                          data-test-id={`kitchen-menu-choice-group-accordion-${choiceGroup.id}`}
                          choiceGroup={choiceGroup}
                          openEditChoiceGroupDrawer={openEditChoiceGroupDrawer}
                        />
                      </Box>
                    )
                )}
              </Grid>
            </Grid>
          ) : (
            <KitchenMenuManagementEmpty>
              <CustomTypography>
                {t(
                  "You haven't added any choice group yet, add them by clicking"
                )}
                <br />
                {`"${t("Create Choice Group")}"`}
              </CustomTypography>
            </KitchenMenuManagementEmpty>
          )}
        </Box>

        <KitchenMenuChoiceGroupFormDrawer
          data-test-id="kitchen-menu-choice-group-form-drawer"
          drawer={choiceGroupDrawer}
          onClose={closeChoiceGroupDrawer}
          createChoiceGroup={createChoiceGroup}
          editChoiceGroup={editChoiceGroup}
        />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(
  withTranslation()(KitchenMenuManagementChoiceGroup)
);
// Customizable Area End
