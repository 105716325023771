// Customizable Area Start
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { WithStyles } from "@material-ui/core";
import { WithTranslation } from "react-i18next";
import { customLocaleMoment } from "../../../components/src/Utils.web";

export const configJSON = require("./config");

type FAQ = {
  id: string;
  type: "faq";
  attributes: {
    id: number;
    question: "What is the Premium Position campaign all about?";
    answer: "The Premium Position campaign is a marketing initiative designed to give your brand or product maximum exposure by securing prime advertising placements across various channels.";
  };
};

export type MonthlyBudget = {
  id: string;
  type: "monthly_budget";
  attributes: {
    id: number;
    budget_value: number;
    view_count: number;
    recommended: boolean;
  };
};

type Advertisement = {
  id: string;
  type: "advertisement";
  attributes: {
    id: number;
    campaign_name: string;
    campaign_description: string;
    custom_budget: string;
    start_date: string;
    end_date: string;
    campaign_status: "approved" | "pending" | "rejected";
    running_status: boolean;
    engagement_view_rate: string;
    monthly_budget: {
      id: number;
      budget_value: number;
      view_count: number;
    };
    revenue: number;
  };
};

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

interface S {
  formCheck: boolean;
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  showCreateScreen: boolean;
  showTable: boolean;
  loading: boolean;
  faqs: FAQ[];
  campaigns: Advertisement[];
  monthlyBudget: MonthlyBudget[];
  monthlyBudgetMap: Map<string, number>;
  monthlyBudgetSelectedId: string;
}

interface SS {}

export default class PromocodesAdvertisingController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetCampaignsCallId = "";
  apiCreateCampaignCallId = "";
  apiGetFAQsCallId = "";
  apiGetMonthlyBudgetCallId = "";
  apiUpdateCampaigncallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      formCheck: true,
      pagination: {
        limit: 4,
        page: 1,
        totalCount: 10,
        totalPages: 6,
      },
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
      showCreateScreen: false,
      showTable: false,
      loading: false,
      faqs: [],
      campaigns: [],
      monthlyBudget: [],
      monthlyBudgetMap: new Map(),
      monthlyBudgetSelectedId: "",
    };
  }

  getMonthlyBudget = () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetMonthlyBudgetCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMonthlyBudgetEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetMonthlyBudget = (responseJson: any) => {
    this.setState({ loading: false });
    if (responseJson && responseJson.data) {
      let monthlyBudgetSelectedId = responseJson.data[1].id;

      responseJson.data.forEach((budget: MonthlyBudget) => {
        this.state.monthlyBudgetMap.set(
          budget.id,
          budget.attributes.view_count
        );

        if (budget.attributes.recommended) {
          monthlyBudgetSelectedId = budget.id;
        }
      });
      this.setState({
        monthlyBudget: responseJson.data,
        monthlyBudgetSelectedId,
      });
    }
  };

  getCampaigns = () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetCampaignsCallId = requestMessage.messageId;

    const queryParams = new URLSearchParams();

    queryParams.append("page", this.state.pagination.page.toString());
    queryParams.append("per_page", this.state.pagination.limit.toString());

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCampaignsEndpoint + "?" + queryParams.toString()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetCampaigns = (responseJson: any) => {
    this.setState({ loading: false });
    if (
      responseJson &&
      responseJson.advertisements &&
      responseJson.advertisements.data
    ) {
      this.setState({
        campaigns: responseJson.advertisements.data,
        pagination: {
          limit: 4,
          page: responseJson.pagination.current_page,
          totalCount: responseJson.pagination.total_count,
          totalPages: responseJson.pagination.total_pages,
        },
        showTable: responseJson.advertisements.data.length > 0,
      });
    }

    if (responseJson && responseJson.errors) {
      this.showSnackbar(
        this.props.t("Error! Please try again"),
        CustomSnackbarType.Error
      );
    }
  };

  createCampaign = (payload: any) => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreateCampaignCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCampaignsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "post"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCreateCampaign = (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson && responseJson.data) {
      this.showSnackbar(
        this.props.t(
          "Success! The campaign was created successfully. Once the admin approves it, it will automatically show up on your list."
        ),
        CustomSnackbarType.Success
      );
      this.setState({ showCreateScreen: false }, () => {
        this.getCampaigns();
      });
    }

    if (responseJson && responseJson.errors) {
      this.showSnackbar(
        this.props.t("Error! Please try again"),
        CustomSnackbarType.Error
      );
    }
  };

  updateCampaign = (budgetId: string, isRunning: boolean) => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    const payload = {
      advertisement: {
        running_status: isRunning,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateCampaigncallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCampaignsEndpoint + "/" + budgetId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "patch"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUpdateCampaign = (responseJson: any) => {
    this.setState({ loading: false });
    if (responseJson && responseJson.data) {
      this.getCampaigns();
    }

    if (responseJson && responseJson.errors) {
      this.showSnackbar(
        "Something went wrong, please try again!",
        CustomSnackbarType.Error
      );
    }
  };

  getFAQs = () => {
    this.setState({ loading: true });
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetFAQsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFAQsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetFAQs = (responseJson: any) => {
    this.setState({ loading: false });
    if (responseJson && responseJson.data) {
      this.setState({ faqs: responseJson.data });
    }

    if (responseJson && responseJson.errors) {
      this.showSnackbar(
        this.props.t("Error! Please try again"),
        CustomSnackbarType.Error
      );
    }
  };

  async componentDidMount() {
    this.getCampaigns();
    this.getFAQs();
    this.getMonthlyBudget();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiGetCampaignsCallId: {
          this.handleGetCampaigns(responseJson);
          break;
        }

        case this.apiCreateCampaignCallId: {
          this.handleCreateCampaign(responseJson);
          break;
        }

        case this.apiGetFAQsCallId: {
          this.handleGetFAQs(responseJson);
          break;
        }

        case this.apiGetMonthlyBudgetCallId: {
          this.handleGetMonthlyBudget(responseJson);
          break;
        }

        case this.apiUpdateCampaigncallId: {
          this.handleUpdateCampaign(responseJson);
          break;
        }
      }
    }
  }

  handleRadioChange = (value: string) => {
    this.setState({ monthlyBudgetSelectedId: value });
  };

  handleChecboxChange = (value: boolean) => {
    this.setState({ formCheck: value });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
    });
  };

  showSnackbar = (message: string, type: CustomSnackbarType) => {
    this.setState({
      snackbar: {
        open: true,
        message,
        type,
      },
    });
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.getCampaigns();
      }
    );
  };

  showCreateScreen = () => {
    this.setState({
      showCreateScreen: true,
    });
  };

  hideCreateScreen = () => {
    this.setState({
      showCreateScreen: false,
    });
  };

  showTable = () => {
    this.setState({ showTable: true });
  };

  reset = () => {
    this.setState({
      formCheck: true,
      pagination: {
        limit: 6,
        page: 1,
        totalCount: 10,
        totalPages: 6,
      },
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
      showCreateScreen: false,
      showTable: false,
    });
  };

  formatCampaignDate = (date: string) => {
    customLocaleMoment.locale(this.props.i18n.language);
    return customLocaleMoment(date).format("DD MMM yyyy");
  };

  getViewOfMonthlyBudget = (budgetId: string) => {
    return this.state.monthlyBudgetMap.get(budgetId);
  };
}

// Customizable Area End
