import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { CustomFilterMenu } from "../../../components/src/CustomFilter.web";
import { SeeChangesDataStatus } from "../../../components/src/CustomSeeChangesDataStatus.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { KitchenMenuCategory } from "../../../components/src/KitchenMenuCategoryAccordion.web";
import { KitchenMenuChoiceGroup } from "../../../components/src/KitchenMenuChoiceGroupAccordion.web";
import {
  KitchenMenuCategoryFormDrawerMode,
  KitchenMenuCategoryFormDrawerProps,
} from "../../../components/src/KitchenMenuCategoryFormDrawer.web";
import {
  KitchenMenuChoiceGroupFormDrawerMode,
  KitchenMenuChoiceGroupFormDrawerProps,
} from "../../../components/src/KitchenMenuChoiceGroupFormDrawer.web";
import {
  KitchenMenuItemFormDrawerProps,
  KitchenMenuItemFormDrawerMode,
} from "../../../components/src/KitchenMenuItemFormDrawer.web";
import { KitchenOrderCatalogueStatus } from "../../../components/src/KitchenOrderCatalogueStatusMenu.web";
import { WithTranslation } from "react-i18next";

export enum MenuTab {
  Overview = "overview",
  ChoiceGroups = "choiceGroups",
  SeeChanges = "seeChanges",
}

export interface SeeChangesData {
  name: string;
  id: string;
  changes: string;
  date: Date;
  author: string;
  status: SeeChangesDataStatus;
}

export interface MenuDish {
  id: string | number;
  name: string;
  available: boolean;
  image: string;
  price: number;
}

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  menuTab: MenuTab;
  filter: string;
  showNotification: boolean;
  menuCategories: KitchenMenuCategory[];
  choiceGroups: KitchenMenuChoiceGroup[];
  seeChangesData: SeeChangesData[];
  categoryDrawer: KitchenMenuCategoryFormDrawerProps;
  choiceGroupDrawer: KitchenMenuChoiceGroupFormDrawerProps;
  itemDrawer: KitchenMenuItemFormDrawerProps;
  loading: boolean;
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  pendingSeeChanges: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class KitchenMenuManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  filterMenuList: CustomFilterMenu[] = [
    {
      id: uuidv4(),
      name: "All",
      value: "all",
    },
  ];

  fetchMenuCategoriesApiId: string = "";
  fetchMenuChoiceGroupsApiId: string = "";
  fetchSeeChangesDataApiId: string = "";
  fetchPendingCountApiId: string = "";
  createCategoryApiId: string = "";
  openCategoryDrawerApiId: string = "";
  getCategoryApiId: string = "";
  editCategoryApiId: string = "";
  openChoiceGroupDrawerApiId: string = "";
  createChoiceGroupApiId: string = "";
  getChoiceGroupApiId: string = "";
  editChoiceGroupApiId: string = "";
  updateSeeChangeStatusApiId: string = "";
  openItemDrawerApiId: string = "";
  updateKitchenMenuCatalogueStatusApiId: string = "";
  createItemApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      menuTab: MenuTab.Overview,
      filter: "All",
      showNotification: true,
      menuCategories: [],
      choiceGroups: [],
      seeChangesData: [],
      categoryDrawer: {
        open: false,
        loading: false,
        options: [],
        mode: KitchenMenuCategoryFormDrawerMode.Add,
        form: {
          id: "",
          name: "",
          image: null,
          categories: [],
        },
      },
      choiceGroupDrawer: {
        open: false,
        loading: false,
        options: [],
        mode: KitchenMenuChoiceGroupFormDrawerMode.Add,
        form: {
          id: "",
          title: "",
          image: null,
          subCategories: [],
        },
      },
      itemDrawer: {
        open: false,
        loading: false,
        options: [],
        mode: KitchenMenuItemFormDrawerMode.Add,
      },
      loading: false,
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
      pagination: {
        page: 1,
        limit: 10,
        totalPages: 0,
        totalCount: 0,
      },
      pendingSeeChanges: 0,
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchMenuCategories();
    this.fetchPendingCount();
    // Customizable Area End
  }

  // Customizable Area Start
  changeMenuTab = (menuTab: MenuTab) => {
    this.setState(
      {
        menuTab,
      },
      () => {
        switch (this.state.menuTab) {
          case MenuTab.Overview:
            this.fetchMenuCategories();
            break;

          case MenuTab.ChoiceGroups:
            this.fetchMenuChoiceGroups();
            break;

          case MenuTab.SeeChanges:
            this.fetchSeeChangesData();
            this.fetchPendingCount();

            break;

          default:
            break;
        }
      }
    );
  };

  changeFilter = (filter: string) => {
    this.setState({
      filter,
    });
  };

  updateSeeChangesDataStatus = async (
    id: string | number,
    value: SeeChangesDataStatus
  ) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateSeeChangeStatusApiId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("new_status", value);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/activity_logs/${id}/update_status`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleUpdateSeeChangesDataStatus = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.fetchSeeChangesData();
    this.fetchPendingCount();
  };

  toggleShowNotification = () => {
    this.setState({
      showNotification: !this.state.showNotification,
    });
  };

  openCategoryDrawer = async (mode: KitchenMenuCategoryFormDrawerMode) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openCategoryDrawerApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,

      categoryDrawer: {
        ...this.state.categoryDrawer,
        mode,
      },
    });
  };

  handleOpenCategoryDrawer = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const options = responseJson.data.map((item: any) => ({
      id: item.attributes.id,
      value: item.attributes.id,
      name: item.attributes.name,
    }));

    this.setState({
      categoryDrawer: {
        ...this.state.categoryDrawer,
        open: true,
        options,
      },
    });
  };

  closeCategoryDrawer = () => {
    this.setState({
      categoryDrawer: {
        open: false,
        options: [],
        loading: false,
        mode: KitchenMenuCategoryFormDrawerMode.Add,
        form: {
          id: "",
          name: "",
          image: null,
          categories: [],
        },
      },
    });
  };

  openItemDrawer = async (mode: KitchenMenuItemFormDrawerMode) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openItemDrawerApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/categorized_list`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,

      itemDrawer: {
        ...this.state.itemDrawer,
        mode,
      },
    });
  };

  handleOpenItemDrawer = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const options = responseJson.data.map((item: any) => ({
      id: item.attributes.id,
      value: item.attributes.id,
      name: item.attributes.name,
    }));

    this.setState({
      itemDrawer: {
        ...this.state.itemDrawer,
        open: true,
        options,
      },
    });
  };

  closeItemDrawer = () => {
    this.setState({
      itemDrawer: {
        open: false,
        options: [],
        loading: false,
        mode: KitchenMenuItemFormDrawerMode.Add,
      },
    });
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  fetchMenuCategories = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchMenuCategoriesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/categorized_list`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchMenuCategories = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const menuCategories = responseJson.data.map((category: any) => ({
      id: category.id,
      name: category.attributes.name,
      image: category.attributes.image_url,
      catalogues: category.attributes.catalogues.map((catalogue: any) => ({
        id: catalogue.id,
        name: catalogue.name,
        available: catalogue.availability,
        image: catalogue.image_url,
        price: catalogue.price,
      })),
    }));

    this.setState({
      menuCategories,
    });
  };

  fetchMenuChoiceGroups = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchMenuChoiceGroupsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/choice_groups`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchMenuChoiceGroups = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const choiceGroups = responseJson.data.map((group: any) => ({
      id: group.id,
      title: group.title,
      image: group.image_url,
      subCategories: group.sub_categories.map((catalogue: any) => ({
        id: catalogue.id,
        name: catalogue.name,
        image: catalogue.image_url,
      })),
    }));

    this.setState({
      choiceGroups,
    });
  };

  fetchSeeChangesData = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchSeeChangesDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/activity_logs?page=${this.state.pagination.page}&items=${this.state.pagination.limit}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchSeeChangesData = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const seeChangesData = responseJson.activity_logs.map((log: any) => ({
      id: log.id,
      name: log.catalogue.name,
      changes: log.message,
      date: log.date,
      author: log.account.email,
      status: log.status,
    }));

    this.setState({
      seeChangesData,
      pagination: {
        limit: 10,
        page: responseJson.pagination.current_page,
        totalCount: responseJson.pagination.total_count,
        totalPages: responseJson.pagination.total_pages,
      },
    });
  };

  fetchPendingCount = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchPendingCountApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/pending_status_count`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFetchPendingCount = async (responseJson: any) => {
    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.setState({
      pendingSeeChanges: responseJson.pending_activity_logs_count,
    });
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchSeeChangesData();
      }
    );
  };

  createCategory = async (values: {
    name: string;
    image: File;
    categories: string[];
  }) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCategoryApiId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("image", values.image);

    values.categories.forEach((category) => {
      formData.append("categories[]", category);
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/sub_categories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      categoryDrawer: {
        ...this.state.categoryDrawer,
        loading: true,
      },
    });
  };

  handleCreateCategory = async (responseJson: any) => {
    this.setState({
      categoryDrawer: {
        ...this.state.categoryDrawer,
        loading: false,
      },
    });

    if (responseJson.errors) {
      this.openSnackbar(CustomSnackbarType.Error, responseJson.errors);

      return;
    }

    if (responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, responseJson.error);

      return;
    }

    this.closeCategoryDrawer();
    this.fetchMenuCategories();
  };

  openEditCategoryDrawer = async (id: string) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/sub_categories/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleOpenEditCategoryDrawer = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const { id, name, image_url, categories } = responseJson.data.attributes;

    this.setState(
      {
        categoryDrawer: {
          ...this.state.categoryDrawer,
          form: {
            id,
            name,
            image: image_url,
            categories: categories.map(
              (category: { id: string }) => category.id
            ),
          },
        },
      },
      () => {
        this.openCategoryDrawer(KitchenMenuCategoryFormDrawerMode.Edit);
      }
    );
  };

  editCategory = async (values: {
    name: string;
    image: File;
    categories: string[];
  }) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCategoryApiId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("name", values.name);

    values.categories.forEach((category) => {
      formData.append("categories[]", category);
    });

    if (typeof values.image !== "string") {
      formData.append("image", values.image);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/sub_categories/${this.state.categoryDrawer.form.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      categoryDrawer: {
        ...this.state.categoryDrawer,
        loading: true,
      },
    });
  };

  handleEditCategory = async (responseJson: any) => {
    this.setState({
      categoryDrawer: {
        ...this.state.categoryDrawer,
        loading: false,
      },
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.closeCategoryDrawer();
    this.fetchMenuCategories();
  };

  openChoiceGroupDrawer = async (
    mode: KitchenMenuChoiceGroupFormDrawerMode
  ) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openChoiceGroupDrawerApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/categorized_list`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,

      choiceGroupDrawer: {
        ...this.state.choiceGroupDrawer,
        mode,
      },
    });
  };

  handleOpenChoiceGroupDrawer = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const options = responseJson.data.map((item: any) => ({
      id: item.attributes.id,
      value: item.attributes.id,
      name: item.attributes.name,
    }));

    this.setState({
      choiceGroupDrawer: {
        ...this.state.choiceGroupDrawer,
        open: true,
        options,
      },
    });
  };

  closeChoiceGroupDrawer = () => {
    this.setState({
      choiceGroupDrawer: {
        open: false,
        options: [],
        loading: false,
        mode: KitchenMenuChoiceGroupFormDrawerMode.Add,
        form: {
          id: "",
          title: "",
          image: null,
          subCategories: [],
        },
      },
    });
  };

  createChoiceGroup = async (values: {
    title: string;
    image: File;
    subCategories: string[];
  }) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createChoiceGroupApiId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("image", values.image);

    values.subCategories.forEach((category) => {
      formData.append("sub_category_ids[]", category);
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/choice_groups`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      choiceGroupDrawer: {
        ...this.state.choiceGroupDrawer,
        loading: true,
      },
    });
  };

  handleCreateChoiceGroup = (responseJson: any) => {
    this.setState({
      choiceGroupDrawer: {
        ...this.state.choiceGroupDrawer,
        loading: false,
      },
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.closeChoiceGroupDrawer();
    this.fetchMenuChoiceGroups();
  };

  openEditChoiceGroupDrawer = async (id: string) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChoiceGroupApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/choice_groups/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleOpenEditChoiceGroupDrawer = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const { id, title, image_url, sub_categories } = responseJson;

    this.setState(
      {
        choiceGroupDrawer: {
          ...this.state.choiceGroupDrawer,
          form: {
            id,
            title,
            image: image_url,
            subCategories: sub_categories.map(
              (category: { id: string }) => category.id
            ),
          },
        },
      },
      () => {
        this.openChoiceGroupDrawer(KitchenMenuChoiceGroupFormDrawerMode.Edit);
      }
    );
  };

  editChoiceGroup = async (values: {
    title: string;
    image: File;
    subCategories: string[];
  }) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editChoiceGroupApiId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("title", values.title);

    values.subCategories.forEach((category) => {
      formData.append("sub_category_ids[]", category);
    });

    if (typeof values.image !== "string") {
      formData.append("image", values.image);
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/choice_groups/${this.state.choiceGroupDrawer.form.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      choiceGroupDrawer: {
        ...this.state.choiceGroupDrawer,
        loading: true,
      },
    });
  };

  handleEditChoiceGroup = async (responseJson: any) => {
    this.setState({
      choiceGroupDrawer: {
        ...this.state.choiceGroupDrawer,
        loading: false,
      },
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.closeChoiceGroupDrawer();
    this.fetchMenuChoiceGroups();
  };

  updateKitchenMenuCatalogueStatus = async (
    id: string,
    value: KitchenOrderCatalogueStatus
  ) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateKitchenMenuCatalogueStatusApiId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("new_availability", value);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `//bx_block_catalogue/catalogues/${id}/change_availability`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleUpdateKitchenMenuCatalogueStatus = async (responseJson: any) => {
    this.setState({
      choiceGroupDrawer: {
        ...this.state.choiceGroupDrawer,
        loading: false,
      },
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.fetchMenuCategories();
  };

  createItem = async (values: {
    image: string | File;
    nameEn: string;
    nameAr: string;
    category: string;
    descriptionEn: string;
    descriptionAr: string;
    price: number;
    variants: {
      id: string;
      name: string;
      price: number;
    }[];
  }) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createItemApiId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("name", values.nameEn);
    formData.append("name_arabic", values.nameAr);
    formData.append("description", values.descriptionEn);
    formData.append("description_arabic", values.descriptionAr);
    formData.append("sub_category_id", values.category);

    formData.append("price", values.price.toString());

    if (typeof values.image !== "string") {
      formData.append("image", values.image);
    }

    values.variants.forEach((variant) => {
      formData.append("variant_name[]", variant.name);
      formData.append("variant_price[]", variant.price.toString());
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/catalogues`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleCreateItem = async (responseJson: any) => {
    this.closeItemDrawer();

    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.fetchMenuCategories();
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchMenuCategoriesApiId:
        this.handleFetchMenuCategories(responseJson);

        break;

      case this.fetchMenuChoiceGroupsApiId:
        this.handleFetchMenuChoiceGroups(responseJson);

        break;

      case this.fetchSeeChangesDataApiId:
        this.handleFetchSeeChangesData(responseJson);

        break;

      case this.fetchPendingCountApiId:
        this.handleFetchPendingCount(responseJson);

        break;

      case this.createCategoryApiId:
        this.handleCreateCategory(responseJson);

        break;

      case this.openCategoryDrawerApiId:
        this.handleOpenCategoryDrawer(responseJson);

        break;

      case this.getCategoryApiId:
        this.handleOpenEditCategoryDrawer(responseJson);

        break;

      case this.editCategoryApiId:
        this.handleEditCategory(responseJson);

        break;

      case this.openChoiceGroupDrawerApiId:
        this.handleOpenChoiceGroupDrawer(responseJson);

        break;

      case this.createChoiceGroupApiId:
        this.handleCreateChoiceGroup(responseJson);

        break;

      case this.getChoiceGroupApiId:
        this.handleOpenEditChoiceGroupDrawer(responseJson);

        break;

      case this.editChoiceGroupApiId:
        this.handleEditChoiceGroup(responseJson);

        break;

      case this.updateSeeChangeStatusApiId:
        this.handleUpdateSeeChangesDataStatus(responseJson);

        break;

      case this.openItemDrawerApiId:
        this.handleOpenItemDrawer(responseJson);

        break;

      case this.updateKitchenMenuCatalogueStatusApiId:
        this.handleUpdateKitchenMenuCatalogueStatus(responseJson);

        break;

      case this.createItemApiId:
        this.handleCreateItem(responseJson);

        break;

      default:
        break;
    }
    // Customizable Area End
  }
}
