// Customizable Area Start
import React from "react";

import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

import { ChevronRightRounded } from "@material-ui/icons";
import { Formik, FormikProps } from "formik";

import CustomButton from "../../../components/src/CustomButton.web";
import CustomFormInputField from "../../../components/src/CustomFormInputField.web";
import CustomFormSelectField from "../../../components/src/CustomFormSelectField.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";

import {
  Box,
  Breadcrumbs,
  ButtonBase,
  Checkbox,
  CircularProgress,
  ListItemText,
  MenuItem,
  WithStyles,
  styled,
} from "@material-ui/core";
import { calenderIcon, iconCheck, iconUncheck } from "./assets";
import {
  Category,
  DiscountType,
  DiscountValue,
  ValidationSchema,
  getValidationSchema,
} from "./PromocodesPromotionsListController.web";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomPromocodesCreateDiscountDialogWeb from "../../../components/src/promocodes/CustomPromocodesCreateDiscountDialog.web";

interface Props extends WithStyles, WithTranslation {
  optionCategories: Category[];
  optionDiscountValues: DiscountValue[];
  optionDiscountTypes: DiscountType[];
  loading: boolean;
  handleCancel(): void;
  closeSnackbar(): void;
  handleSubmit(values: ValidationSchema): void;
  handleCreateDiscount(
    payload: any,
    type: "discount_type" | "discount_value"
  ): void;
}

const StyledListItemText = styled(ListItemText)({
  textAlign: "start",
});

const styles = (theme: Theme) =>
  createStyles({
    btnCancel: {
      color: "#FFFFFF",
      backgroundColor: "#333333",
      "&:hover": {
        backgroundColor: "rgb(91, 91, 91)",
      },
    },
    inputDescription: {
      "& .MuiOutlinedInput-multiline": {
        padding: 0,
      },
    },
    breadcrumbs: {
      "& .MuiButtonBase-root": {
        padding: 0,
      },
      "& .MuiButton-label": {
        fontSize: "0.875rem",
        fontFamily: "Raleway",
        lineHeight: 22 / 14,
        fontWeight: 400,
      },
    },
    form: {
      width: "100%",
      maxWidth: 650,
      marginTop: 24,
      "& .form__actions": {
        display: "flex",
        alignItems: "center",
        marginBlockStart: 24,
        gap: "0.75rem",
        "& .form__actions__help-text": {
          marginInlineEnd: "auto",
          "& button": { color: "#FF6666" },
        },
        "& .form__actions__button": {
          width: "100%",
          maxWidth: 86,
        },
        [theme.breakpoints.down("xs")]: {
          flexDirection: "column",
          "& .form__actions__button": {
            maxWidth: "100%",
          },
        },
      },
      "& .form__input__title": {
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: 1.5,
        marginBlockStart: "2.125rem",
      },
      "& .form__input__description": {
        lineHeight: 24 / 14,
        marginBottom: 12,
      },
      "& .form__input": {
        marginBlockStart: "0.75rem",
      },
      "& .form__group": {
        display: "flex",
        width: "100%",
        alignItems: "center",
        "&.align-start": {
          alignItems: "flex-start",
        },
        gap: "1rem",
        "& > div": {
          marginBlockStart: 0,
          "& .MuiOutlinedInput-input": {
            paddingInlineEnd: "2.5em",
            paddingInlineStart: ".5em",
            paddingBlock: ".6250em",
          },

          "& .MuiInputBase-root": {
            height: 44,
          },
        },
        "& > *": {
          flex: 1,
          minWidth: 0,
        },
        "& .MuiFormHelperText-contained": {
          marginInline: 0,
        },
      },
    },
    formInputOption: {
      "& > span": {
        fontFamily: "Raleway",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        marginBlock: 0,
      },
    },
    formMultiselect: {
      "& ul": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "2rem",
        maxHeight: 220,
        backgroundColor: "#F8F8F8",
        "& li, & li.MuiListItem-root.Mui-selected": {
          backgroundColor: "#F8F8F8",
        },
        "& li:first-child": {
          gridColumn: "1/-1",
        },

        [theme.breakpoints.down("xs")]: {
          gridTemplateColumns: "1fr",
        },
      },
    },
    formMultiselectInput: {
      "&.MuiSelect-root": {
        display: "block",
      },
    },
  });

export class PromocodesPromotionsCreate extends React.Component<Props> {
  formikRef: FormikProps<ValidationSchema> | null = null;

  constructor(props: Props) {
    super(props);
  }

  renderSubmitButton = () => {
    return (
      <CustomButton
        variant="contained"
        color="primary"
        type="submit"
        className="form__actions__button"
        id="btnCreatePromotion"
      >
        {this.props.loading ? (
          <CircularProgress color="inherit" size={"1.5rem"} />
        ) : (
          this.props.t("Save")
        )}
      </CustomButton>
    );
  };

  checkEror = (
    touched: boolean | undefined,
    error: string | string[] | undefined
  ) => {
    return touched && !!error;
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.t !== this.props.t) {
      const touched = this.formikRef?.touched;

      if (touched) {
        this.formikRef?.setTouched(touched);
      }
    }
  }

  render() {
    const {
      classes,
      optionCategories,
      optionDiscountTypes,
      optionDiscountValues,
      handleCancel,
      handleSubmit,
      t,
      i18n,
    } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    const minDate = new Date();

    return (
      <>
        <Breadcrumbs
          className={classes.breadcrumbs}
          separator={<ChevronRightRounded />}
          dir={dir}
        >
          <CustomButton variant="text" color="primary" onClick={handleCancel}>
            {t("Promotions")}
          </CustomButton>
          <CustomButton variant="text">{t("Create Promotions")}</CustomButton>
        </Breadcrumbs>

        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          dir={dir}
        >
          <Formik
            initialValues={getValidationSchema.fn(t).default()}
            validationSchema={getValidationSchema.fn(t)}
            onSubmit={handleSubmit}
            innerRef={(instance) => {
              this.formikRef = instance;
            }}
          >
            {(props) => {
              return (
                <form
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                  dir={dir}
                >
                  <CustomFormInputField
                    label={t("Promotions Name")}
                    variant="outlined"
                    placeholder={t("Enter name")}
                    fullWidth
                    name="name"
                    value={props.values.name}
                    error={this.checkEror(
                      props.touched.name,
                      props.errors.name
                    )}
                  />
                  <CustomFormInputField
                    variant="outlined"
                    placeholder={t("Enter description")}
                    multiline
                    minRows={3}
                    maxRows={3}
                    fullWidth
                    name="description"
                    value={props.values.description}
                    className={classes.inputDescription}
                    error={this.checkEror(
                      props.touched.description,
                      props.errors.description
                    )}
                  />

                  <CustomTypography className="form__input__title">
                    {t("All Categories")}
                  </CustomTypography>
                  <CustomTypography className="form__input__description">
                    {t(
                      "Select the categories which where you would like to add promotions"
                    )}
                  </CustomTypography>

                  <CustomFormSelectField
                    fullWidth
                    name="sub_category_ids"
                    label={t("Select one or more")}
                    variant="outlined"
                    error={this.checkEror(
                      props.touched.sub_category_ids,
                      props.errors.sub_category_ids
                    )}
                    multiple
                    inputProps={{
                      renderValue: (selected: string[]) =>
                        optionCategories
                          .filter((category) => selected.includes(category.id))
                          .map((category) => category.attributes.name)
                          .join(", "),
                      placeholder: t("Select Categories"),
                      className: classes.formMultiselectInput,
                    }}
                    MenuProps={{
                      className: classes.formMultiselect,
                      PaperProps: {
                        className: classes.formMultiselectContainer,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={(event) => {
                      const value = event.target.value as string[];
                      if (value[value.length - 1] === "All") {
                        props.setFieldValue(
                          "sub_category_ids",
                          props.values.sub_category_ids.length ===
                            optionCategories.length
                            ? []
                            : optionCategories.map((category) => category.id)
                        );
                        return;
                      }
                      props.setFieldValue("sub_category_ids", value);
                    }}
                    dir={dir}
                  >
                    <MenuItem value="All" dir={dir}>
                      <Checkbox
                        icon={<img src={iconUncheck} width={20} />}
                        checkedIcon={<img src={iconCheck} width={20} />}
                        checked={
                          optionCategories.length ===
                          props.values.sub_category_ids.length
                        }
                      />
                      <StyledListItemText primary={t("Select All")} dir={dir} />
                    </MenuItem>
                    {optionCategories.map((category) => (
                      <MenuItem key={category.id} value={category.id} dir={dir}>
                        <Checkbox
                          icon={<img src={iconUncheck} width={20} />}
                          checkedIcon={<img src={iconCheck} width={20} />}
                          checked={
                            props.values.sub_category_ids.indexOf(category.id) >
                            -1
                          }
                        />
                        <StyledListItemText
                          primary={category.attributes.name}
                          dir={dir}
                        />
                      </MenuItem>
                    ))}
                  </CustomFormSelectField>

                  <CustomTypography className="form__input__title">
                    {t("Discount Type")}
                  </CustomTypography>
                  <CustomTypography className="form__input__description">
                    {t("Exploring Discount Varieties")}
                  </CustomTypography>

                  <div className="form__group align-start">
                    <CustomFormSelectField
                      fullWidth
                      name="discount_type_id"
                      label={t("Discount Type")}
                      variant="outlined"
                      error={
                        props.touched.discount_type_id &&
                        !!props.errors.discount_type_id
                      }
                      dir={dir}
                    >
                      {optionDiscountTypes.map((discountType) => (
                        <MenuItem key={discountType.id} value={discountType.id}>
                          <StyledListItemText
                            primary={discountType.attributes.discount_type}
                            className={classes.formInputOption}
                            dir={dir}
                          />
                        </MenuItem>
                      ))}
                    </CustomFormSelectField>

                    <CustomFormSelectField
                      fullWidth
                      name="discount_value_id"
                      label={t("Discount Value")}
                      variant="outlined"
                      error={
                        props.touched.discount_value_id &&
                        !!props.errors.discount_value_id
                      }
                      dir={dir}
                    >
                      {optionDiscountValues.map((discountValue) => (
                        <MenuItem
                          key={discountValue.id}
                          value={discountValue.id}
                        >
                          <StyledListItemText
                            primary={discountValue.attributes.discount_value}
                            className={classes.formInputOption}
                            dir={dir}
                          />
                        </MenuItem>
                      ))}
                    </CustomFormSelectField>
                  </div>

                  <div className="form__group align-start">
                    <CustomPromocodesCreateDiscountDialogWeb
                      type="discount_type"
                      onCreate={(payload) =>
                        this.props.handleCreateDiscount(
                          payload,
                          "discount_type"
                        )
                      }
                    />

                    <CustomPromocodesCreateDiscountDialogWeb
                      type="discount_value"
                      onCreate={(payload) =>
                        this.props.handleCreateDiscount(
                          payload,
                          "discount_value"
                        )
                      }
                    />
                  </div>

                  <CustomTypography className="form__input__title">
                    {t("Schedule promotions")}
                  </CustomTypography>
                  <CustomTypography className="form__input__description">
                    {t("Strategic Timing: Scheduling Your Promotions")}
                  </CustomTypography>

                  <div id="startDatePortal"></div>
                  <div id="endDatePortal"></div>
                  <div className="form__group align-start">
                    <CustomDatePicker
                      onChange={(date) => {
                        props.setFieldValue("from", date);
                      }}
                      showTimeSelect
                      placeholderText={t("Start Date")}
                      portalId="startDatePortal"
                      name="from"
                      selected={props.values.from}
                      dateFormat={"dd/MM/yyyy hh:mma"}
                      minDate={minDate}
                      customInput={
                        <CustomFormInputField
                          label={t("Start Date")}
                          variant="outlined"
                          fullWidth
                          error={props.touched.from && !!props.errors.from}
                          InputProps={{
                            endAdornment: <img src={calenderIcon} />,
                          }}
                        />
                      }
                      locale={i18n.language}
                    />
                    <CustomDatePicker
                      onChange={(date) => {
                        props.setFieldValue("to", date);
                      }}
                      showTimeSelect
                      placeholderText={t("End Date")}
                      portalId="endDatePortal"
                      name="to"
                      selected={props.values.to}
                      dateFormat={"dd/MM/yyyy hh:mma"}
                      minDate={minDate}
                      customInput={
                        <CustomFormInputField
                          label={t("End Date")}
                          variant="outlined"
                          fullWidth
                          error={props.touched.to && !!props.errors.to}
                          InputProps={{
                            endAdornment: <img src={calenderIcon} />,
                          }}
                        />
                      }
                      locale={i18n.language}
                    />
                  </div>

                  <div className="form__actions" dir={dir}>
                    <CustomTypography className="form__actions__help-text">
                      {t("Need help creating a promotion")}?{" "}
                      <ButtonBase>{t("Click here")}</ButtonBase>
                    </CustomTypography>

                    <CustomButton
                      type="button"
                      variant="contained"
                      color="primary"
                      className={classes.btnCancel + " form__actions__button"}
                      onClick={handleCancel}
                    >
                      {t("Cancel")}
                    </CustomButton>
                    {this.renderSubmitButton()}
                  </div>
                </form>
              );
            }}
          </Formik>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(PromocodesPromotionsCreate)
);
// Customizable Area End
