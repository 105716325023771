// Customizable Area Start
import React from "react";
import {
  Box,
  withStyles,
  createStyles,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Theme,
  InputAdornment,
  WithStyles,
} from "@material-ui/core";
import { Close, Menu, Search } from "@material-ui/icons";
import NavigationMenuAdminController, {
  Props,
} from "./NavigationMenuAdminController.web";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  withRouter,
  matchPath,
} from "react-router-dom";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomTypography from "../../../components/src/CustomTypography.web";
import clsx from "clsx";
import {
  logout,
  bgKitchenNav,
  notification,
  bgKitchenNavReverse,
  logoOnly,
  notificationActive,
} from "./assets";
import CustomInputField from "../../../components/src/CustomInputField.web";
import AdminLogoutDialog from "../../../components/src/AdminLogoutDialog.web";
import { Skeleton } from "@material-ui/lab";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",

      "&.rtl": {
        flexDirection: "row-reverse",
      },
    },

    appBar: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - 265px)`,
        marginLeft: 265,
      },

      "&.MuiAppBar-colorDefault": {
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
      },

      "&.rtl": {
        [theme.breakpoints.up("sm")]: {
          marginLeft: 0,
          marginRight: 265,
        },
      },
    },

    appBarHead: {
      position: "relative",
      height: 9,

      "&::before": {
        position: "absolute",
        content: '""',
        top: 0,
        left: 0,
        width: "50%",
        height: 9,
        backgroundColor: "#FF6666",
      },

      "&::after": {
        position: "absolute",
        content: '""',
        top: 0,
        right: 0,
        width: "50%",
        height: 9,
        backgroundColor: "#FFCC00",
      },
    },

    appBarHeadToolbar: {
      flexWrap: "wrap",
      borderBottom: "1px solid #EDEDED",
      gap: 8,

      [theme.breakpoints.up("lg")]: {
        paddingRight: 80,
      },

      [theme.breakpoints.down("xs")]: {
        paddingTop: 10,
        paddingBottom: 10,
      },

      "&.rtl": {
        direction: "rtl",

        [theme.breakpoints.up("lg")]: {
          paddingRight: 10,
          paddingLeft: 80,
        },
      },
    },

    welcome: {
      flex: 1,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontWeight: "700 !important" as any,
    },

    adminPageName: {
      overflow: "hidden",
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },

    adminAppBarTool: {
      marginLeft: "auto",
      alignItems: "center",
      display: "flex",
      gap: 15,

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginLeft: 0,
        order: 3,
        marginTop: 12,
      },
    },

    adminAppBarInfo: {
      gap: 15,
      alignItems: "center",
      display: "flex",

      [theme.breakpoints.down("xs")]: {
        marginLeft: "auto",
        order: 2,
      },
    },

    adminAppBarNotification: {
      width: 44,
      backgroundColor: "#F8F8F8",
      border: "1px solid #EDEDED",
      height: 44,

      "&[data-active='true']": {
        borderColor: "rgba(0, 0, 0, 0.08)",
        backgroundColor: "#F66",
      },
    },

    adminAppBarNotificationBadge: {
      "& .MuiBadge-colorPrimary": {
        backgroundColor: "#2EBD6B",
      },

      "& .MuiBadge-dot": {
        height: 5,
        minWidth: 5,
      },
    },

    adminAppBarAvatar: {
      width: 32,
      height: 32,
      backgroundColor: "#FF6666",
    },

    adminMain: {
      padding: theme.spacing(3),
      overflow: "hidden",
      flexGrow: 1,

      [theme.breakpoints.up("lg")]: {
        paddingRight: 80,
      },

      [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 265px)",
      },

      "&.rtl": {
        [theme.breakpoints.up("lg")]: {
          paddingRight: 10,
          paddingLeft: 80,
        },
      },
    },

    adminMainToolbar: {
      [theme.breakpoints.down("xs")]: {
        height: 125,
      },
    },

    adminLanguageSelect: {
      "& fieldset": {
        border: "none",
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },

    adminLanguageMenuItem: {
      display: "flex",
      alignItems: "center",

      "&.rtl": {
        flexDirection: "row-reverse",
      },
    },

    adminLanguageMenuItemFlag: {
      objectFit: "cover",
      width: 24,
      height: 18,
    },

    adminLanguageMenuItemName: {
      marginLeft: 8,
      fontSize: "12px !important",
      lineHeight: "18px !important",

      "&.rtl": {
        marginLeft: 0,
        marginRight: 8,
      },
    },

    adminDrawerMobile: {
      [theme.breakpoints.up("sm")]: {
        width: 265,
        flexShrink: 0,
      },
    },

    adminDrawerMobilePaper: {
      minHeight: "100vh",
      width: 265,
      backgroundColor: "#FFFBF4",

      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    adminDrawerWeb: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },

      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },

    adminDrawerWebPaper: {
      backgroundColor: "#FFFBF4",
      boxSizing: "border-box",
      width: 265,
    },

    adminIconButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    adminNav: {
      [theme.breakpoints.up("sm")]: {
        flexShrink: 0,
        width: 265,
      },
    },

    adminSearch: {
      "& svg": {
        color: "#FF6666",
      },
    },
  });

export interface AdminRoute {
  to: string;
  name: string;
  icon?: string;
  component?: React.ReactNode;
  child?: boolean;
}

class AdminPageComponent extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }

  async componentWillMount() {
    await this.props.initAdminPage();
  }

  async componentWillUnmount() {
    await this.props.setCurrentAdminPage({
      to: "",
      name: "",
    });
  }

  kitchenNavigate = (to: string) => {
    const { routes } = this.props;
    const currentAdminPage = routes.find(
      (route: { to: string; name: string }) =>
        matchPath(to, {
          path: route.to,
          exact: true,
          strict: false,
        })
    );

    if (!currentAdminPage) {
      return;
    }

    this.props.history.push(to);
  };

  render() {
    const {
      setCurrentAdminPage,
      component: Component,
      id,
      navigation,
    } = this.props;

    const newNavigation = {
      setCurrentAdminPage,
      ...navigation,
      ...this.props,
      kitchenNavigate: this.kitchenNavigate,
    };

    return <Component navigation={newNavigation} id={id} />;
  }
}

const AdminPage = withRouter(AdminPageComponent);

const ComingSoon = () => (
  <Box>
    <CustomTypography variant="h2">Coming soon !</CustomTypography>
  </Box>
);

const navMenuAdminInnerStyles = (theme: Theme) =>
  createStyles({
    navInner: {
      paddingTop: 20,

      "&.rtl": {
        direction: "rtl",
      },
    },

    navTitle: {
      padding: "0 15px",
    },

    navItem: {
      gap: 8,
      padding: "8px 15px",
      display: "flex",
      alignItems: "center",

      "&.rtl": {
        textAlign: "right",
      },

      "&.active": {
        color: "#FFFFFF",
        backgroundColor: "#FF6666",

        "& img": {
          filter: "grayscale(100%) brightness(500%)",
        },
      },
    },

    navItemIcon: {
      width: 24,
      height: 24,
    },

    navItemName: {
      marginLeft: 8,

      "& .MuiTypography-root": {
        fontFamily: "Raleway",
        fontSize: 14,
        lineHeight: "24px",
      },
    },
  });

interface NavMenuAdminInnerProps extends WithStyles, WithTranslation {
  title: string;
  currentAdminPage: { name: string; to: string };
  routes: AdminRoute[];

  setCurrentAdminPage(currentAdminPage: { name: string; to: string }): void;
}

const NavMenuAdminInner = withStyles(navMenuAdminInnerStyles)(
  withTranslation()((props: NavMenuAdminInnerProps) => {
    const rtl = props.i18n.language === "ar";

    return (
      <Box
        className={clsx(props.classes.navInner, {
          ["rtl"]: rtl,
        })}
      >
        <CustomTypography className={props.classes.navTitle} variant="h5">
          {props.t(props.title)}
        </CustomTypography>

        <List>
          {props.routes.map((route) => {
            return (
              !route.child && (
                <ListItem
                  className={clsx(props.classes.navItem, {
                    ["rtl"]: rtl,
                    active: matchPath(props.currentAdminPage.to, {
                      path: route.to,
                      exact: false,
                      strict: false,
                    }),
                  })}
                  disableGutters
                  button
                  component={Link}
                  to={route.to}
                  key={route.to}
                  onClick={() =>
                    props.setCurrentAdminPage({
                      name: route.name,
                      to: route.to,
                    })
                  }
                  data-test-id={route.name}
                >
                  <img
                    src={route.icon}
                    alt="image/svg+xml"
                    className={props.classes.navItemIcon}
                  />

                  <ListItemText
                    primary={props.t(route.name)}
                    className={props.classes.navItemName}
                  />
                </ListItem>
              )
            );
          })}
        </List>
      </Box>
    );
  })
);

const navMenuAdminStyles = (theme: Theme) =>
  createStyles({
    adminNavMenu: {
      background: `url(${bgKitchenNav})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "100%",
      backgroundSize: "contain",
      height: "100%",

      "&.rtl": {
        background: `url(${bgKitchenNavReverse})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "100%",
        backgroundSize: "contain",
        height: "100%",
      },
    },

    navMenuToolbar: {
      paddingLeft: 15,
      paddingRight: 15,

      "&.rtl": {
        direction: "rtl",
      },
    },

    navLogo: {
      width: 40,
      height: 40,
    },

    navDivider: {
      height: 4,
      borderRadius: "initial",
    },

    navMenuBottom: {
      paddingLeft: 20,
      paddingRight: 20,
      marginTop: 27,

      "&.rtl": {
        textAlign: "right",
      },
    },

    navMenuBottomInner: {
      paddingTop: 12,
      paddingBottom: 24,
      borderTop: "1px solid #EDEDED",
    },

    logoutBtn: {
      fontWeight: 400,
      fontFamily: "Raleway",
      fontSize: 14,
      lineHeight: "24px",

      "&.rtl": {
        direction: "rtl",
      },
    },

    logoutBtnLogo: {
      "&.rtl": {
        marginLeft: "8px !important",
        marginRight: "0 !important",
      },
    },

    navLogoHeading: {
      display: "flex",
      alignItems: "center",
    },
  });

interface NavMenuAdminProps extends WithTranslation, WithStyles {
  currentAdminPage: { name: string; to: string };
  performanceMonitoringRoutes: AdminRoute[];
  growYourBusinessRoutes: AdminRoute[];
  manageYourBusinessRoutes: AdminRoute[];
  userRoutes: AdminRoute[];

  handleLogout(): void;
  setCurrentAdminPage(currentAdminPage: { name: string; to: string }): void;
}

const NavMenuAdmin = withStyles(navMenuAdminStyles)(
  withTranslation()((props: NavMenuAdminProps) => {
    const rtl = props.i18n.language === "ar";

    return (
      <Box
        className={clsx(props.classes.adminNavMenu, {
          ["rtl"]: rtl,
        })}
      >
        <Toolbar
          className={clsx(props.classes.navMenuToolbar, {
            ["rtl"]: rtl,
          })}
        >
          <Box className={props.classes.navLogoHeading}>
            <img
              className={props.classes.navLogo}
              alt="pickup"
              src={logoOnly}
            />

            <CustomTypography variant="subtitle2">ZE PICKUP</CustomTypography>
          </Box>
        </Toolbar>

        <NavMenuAdminInner
          data-test-id="performance-monitoring"
          title={props.t("Performance Monitoring")}
          routes={props.performanceMonitoringRoutes}
          setCurrentAdminPage={props.setCurrentAdminPage}
          currentAdminPage={props.currentAdminPage}
        />

        <Divider color="#EDEDED" className={props.classes.navDivider} />

        <NavMenuAdminInner
          data-test-id="grow-your-business"
          title={props.t("Grow your Business")}
          routes={props.growYourBusinessRoutes}
          setCurrentAdminPage={props.setCurrentAdminPage}
          currentAdminPage={props.currentAdminPage}
        />

        <Divider color="#EDEDED" className={props.classes.navDivider} />

        <NavMenuAdminInner
          data-test-id="manage-your-business"
          title={props.t("Manage your Business")}
          routes={props.manageYourBusinessRoutes}
          setCurrentAdminPage={props.setCurrentAdminPage}
          currentAdminPage={props.currentAdminPage}
        />

        <Box
          className={clsx(props.classes.navMenuBottom, {
            ["rtl"]: rtl,
          })}
        >
          <Box className={props.classes.navMenuBottomInner}>
            <CustomButton
              data-test-id="logout-btn"
              className={clsx(props.classes.logoutBtn, { ["rtl"]: rtl })}
              color="inherit"
              variant="text"
              startIcon={
                <img
                  src={logout}
                  alt="logout"
                  className={clsx(props.classes.logoutBtnLogo, {
                    ["rtl"]: rtl,
                  })}
                />
              }
              onClick={props.handleLogout}
            >
              {props.t("Logout")}
            </CustomButton>
          </Box>
        </Box>
      </Box>
    );
  })
);
// Customizable Area End

export class NavigationMenuAdmin extends NavigationMenuAdminController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      languages,
      selectedLanguage,
      mobileOpen,
      currentAdminPage,
      search,
      adminLogoutDialog,
      userProfile,
    } = this.state;

    const { classes, navigation, t, i18n } = this.props;

    const rtl = i18n.language === "ar";

    return (
      <Router>
        <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, { ["rtl"]: rtl })}
            color="default"
          >
            <Toolbar
              className={clsx(classes.appBarHeadToolbar, { ["rtl"]: rtl })}
            >
              <IconButton
                className={classes.adminIconButton}
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
              >
                <Menu />
              </IconButton>

              {this.hideNamePages.includes(currentAdminPage.name) ? (
                <CustomTypography className={classes.welcome}>
                  {userProfile ? (
                    `${t("Welcome")}, ${
                      userProfile?.attributes.user_name ?? ""
                    }  👋`
                  ) : (
                    <Skeleton variant="text" width={120} height={18} />
                  )}
                </CustomTypography>
              ) : (
                <CustomTypography
                  className={classes.adminPageName}
                  variant="h2"
                >
                  {t(currentAdminPage.name)}
                </CustomTypography>
              )}

              <Box className={classes.adminAppBarTool}>
                <CustomInputField
                  className={classes.adminSearch}
                  variant="outlined"
                  placeholder={t("Search")}
                  inputRef={this.inputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {search && (
                          <IconButton
                            data-test-id="clear-search-order-id-btn"
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={this.clearSearch}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    ),
                  }}
                  onKeyDown={this.search}
                />

                <CustomSelect
                  data-test-id="language-select"
                  className={classes.adminLanguageSelect}
                  variant="outlined"
                  value={selectedLanguage}
                  onChange={this.changeLanguage}
                >
                  {languages.map((language) => (
                    <MenuItem
                      className={clsx(classes.adminLanguageMenuItem, {
                        ["rtl"]: rtl,
                      })}
                      value={language.value}
                      key={language.value}
                    >
                      <img
                        className={classes.adminLanguageMenuItemFlag}
                        src={language.flag}
                        alt={language.name}
                      />
                      <CustomTypography
                        variant="subtitle1"
                        className={clsx(classes.adminLanguageMenuItemName, {
                          ["rtl"]: rtl,
                        })}
                      >
                        {t(language.name)}
                      </CustomTypography>
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Box>

              <Box className={classes.adminAppBarInfo}>
                <Link
                  to="/kitchen/admin/notifications-all"
                  style={{ textDecoration: "none" }}
                >
                  <IconButton
                    className={classes.adminAppBarNotification}
                    data-active={this.checkIfNotificationPageActive()}
                    onClick={this.setCurrentAdminPage.bind(this, {
                      name: "Notifications All",
                      to: "/kitchen/admin/notifications-all",
                    })}
                  >
                    <Badge
                      className={classes.adminAppBarNotificationBadge}
                      color="primary"
                      variant="dot"
                      overlap="circular"
                    >
                      {this.checkIfNotificationPageActive() ? (
                        <img
                          src={notificationActive}
                          alt="notification active"
                        />
                      ) : (
                        <img src={notification} alt="notification" />
                      )}
                    </Badge>
                  </IconButton>
                </Link>
                <Link
                  to="/kitchen/admin/profile"
                  onClick={this.setCurrentAdminPage.bind(this, {
                    name: "Profile",
                    to: "/kitchen/admin/profile",
                  })}
                  style={{ textDecoration: "none" }}
                >
                  {userProfile ? (
                    <Avatar
                      className={classes.adminAppBarAvatar}
                      src={userProfile?.attributes.image_url ?? ""}
                    >
                      {this.getAvatarName()}
                    </Avatar>
                  ) : (
                    <>
                      <Skeleton variant="circle" width={32} height={32} />
                    </>
                  )}
                </Link>
              </Box>
            </Toolbar>
          </AppBar>

          <Box component="nav" className={classes.adminNav}>
            <Drawer
              anchor={rtl ? "right" : "left"}
              className={classes.adminDrawerMobile}
              variant="temporary"
              open={mobileOpen}
              onTransitionEnd={this.handleDrawerTransitionEnd}
              ModalProps={{
                keepMounted: true,
              }}
              classes={{
                paper: classes.adminDrawerMobilePaper,
              }}
              onClose={this.handleDrawerClose}
            >
              <NavMenuAdmin
                data-test-id="drawer-mobile"
                performanceMonitoringRoutes={this.performanceMonitoringRoutes}
                growYourBusinessRoutes={this.growYourBusinessRoutes}
                manageYourBusinessRoutes={this.manageYourBusinessRoutes}
                userRoutes={this.userRoutes}
                currentAdminPage={currentAdminPage}
                setCurrentAdminPage={this.setCurrentAdminPage}
                handleLogout={this.handleLogout}
              />
            </Drawer>

            <Drawer
              anchor={rtl ? "right" : "left"}
              variant="permanent"
              className={classes.adminDrawerWeb}
              classes={{
                paper: classes.adminDrawerWebPaper,
              }}
              open
            >
              <NavMenuAdmin
                data-test-id="drawer-web"
                performanceMonitoringRoutes={this.performanceMonitoringRoutes}
                growYourBusinessRoutes={this.growYourBusinessRoutes}
                manageYourBusinessRoutes={this.manageYourBusinessRoutes}
                userRoutes={this.userRoutes}
                currentAdminPage={currentAdminPage}
                setCurrentAdminPage={this.setCurrentAdminPage}
                handleLogout={this.openAdminLogoutDialog}
              />
            </Drawer>
          </Box>

          <Box
            component="main"
            className={clsx(classes.adminMain, {
              ["rtl"]: rtl,
            })}
          >
            <Toolbar className={classes.adminMainToolbar} />
            <Switch>
              {this.allRoutes.map((route: any) => (
                <Route path={route.to} key={route.to} exact={route.exact}>
                  {route.component ? (
                    <AdminPage
                      data-test-id={route.name}
                      id={route.name}
                      component={route.component}
                      setCurrentAdminPage={this.setCurrentAdminPage}
                      navigation={navigation}
                      routes={this.allRoutes}
                      initAdminPage={this.initAdminPage}
                    />
                  ) : (
                    <ComingSoon data-test-id={route.name} />
                  )}
                </Route>
              ))}

              <Redirect from="/kitchen/admin*" to="/kitchen/admin/dashboard" />
            </Switch>
          </Box>

          <AdminLogoutDialog
            data-test-id="admin-logout-dialog"
            open={adminLogoutDialog}
            onClose={this.closeAdminLogoutDialog}
            onLogout={this.handleLogout}
          />
        </Box>
      </Router>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(withTranslation()(NavigationMenuAdmin));
// Customizable Area End
