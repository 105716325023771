import React from "react";
// Customizable Area Start
import { Box, withStyles, createStyles, Tab } from "@material-ui/core";
import KitchenMenuManagementController, {
  MenuTab,
  Props,
} from "./KitchenMenuManagementController.web";
import CustomTabs from "../../../components/src/CustomTabs";
import KitchenMenuManagementOverview from "./KitchenMenuManagementOverview.web";
import KitchenMenuManagementChoiceGroup from "./KitchenMenuManagementChoiceGroup.web";
import KitchenMenuManagementSeeChanges from "./KitchenMenuManagementSeeChanges.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import { KitchenMenuCategoryFormDrawerMode } from "../../../components/src/KitchenMenuCategoryFormDrawer.web";
import { KitchenMenuChoiceGroupFormDrawerMode } from "../../../components/src/KitchenMenuChoiceGroupFormDrawer.web";
import { KitchenMenuItemFormDrawerMode } from "../../../components/src/KitchenMenuItemFormDrawer.web";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

const styles = createStyles({
  container: {
    "&.rtl": {
      direction: "rtl",
    },
  },

  content: {
    padding: 12,
    borderRadius: 8,
    border: "1px solid #EDEDED",
    minHeight: 300,
    borderTopLeftRadius: 0,
  },
});
// Customizable Area End

export class KitchenMenuManagement extends KitchenMenuManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, t, i18n } = this.props;

    const {
      menuTab,
      filter,
      menuCategories,
      choiceGroups,
      seeChangesData,
      showNotification,
      categoryDrawer,
      itemDrawer,
      loading,
      snackbar,
      pagination,
      pendingSeeChanges,
      choiceGroupDrawer,
    } = this.state;

    const rtl = i18n.language === "ar";

    return (
      <Box className={clsx(classes.container, { rtl })}>
        <CustomLoadingBackdrop open={loading} />

        <Box className={classes.inner}>
          <CustomTabs variant="scrollable" scrollButtons="auto" value={menuTab}>
            <Tab
              data-test-id="overview-tab"
              label={t("Overview")}
              value={MenuTab.Overview}
              onClick={() => this.changeMenuTab(MenuTab.Overview)}
            />
            <Tab
              data-test-id="choice-groups-tab"
              label={t("Choice Groups")}
              value={MenuTab.ChoiceGroups}
              onClick={() => this.changeMenuTab(MenuTab.ChoiceGroups)}
            />
            <Tab
              data-test-id="see-changes-tab"
              label={t("See Changes {{number}}", {
                number: pendingSeeChanges ? `(${pendingSeeChanges})` : "",
              })}
              value={MenuTab.SeeChanges}
              onClick={() => this.changeMenuTab(MenuTab.SeeChanges)}
            />
          </CustomTabs>

          <Box className={classes.content}>
            {(() => {
              switch (menuTab) {
                case MenuTab.Overview:
                  return (
                    <KitchenMenuManagementOverview
                      data-test-id="kitchen-menu-management-overview"
                      filter={filter}
                      categoryDrawer={categoryDrawer}
                      itemDrawer={itemDrawer}
                      menuCategories={menuCategories}
                      filterMenuList={this.filterMenuList}
                      changeFilter={this.changeFilter}
                      openCategoryDrawer={() =>
                        this.openCategoryDrawer(
                          KitchenMenuCategoryFormDrawerMode.Add
                        )
                      }
                      closeCategoryDrawer={this.closeCategoryDrawer}
                      openItemDrawer={() =>
                        this.openItemDrawer(KitchenMenuItemFormDrawerMode.Add)
                      }
                      closeItemDrawer={this.closeItemDrawer}
                      createCategory={this.createCategory}
                      editCategory={this.editCategory}
                      openEditCategoryDrawer={this.openEditCategoryDrawer}
                      updateKitchenMenuCatalogueStatus={
                        this.updateKitchenMenuCatalogueStatus
                      }
                      createItem={this.createItem}
                    />
                  );

                case MenuTab.ChoiceGroups:
                  return (
                    <KitchenMenuManagementChoiceGroup
                      data-test-id="kitchen-menu-management-choice-groups"
                      choiceGroups={choiceGroups}
                      choiceGroupDrawer={choiceGroupDrawer}
                      openChoiceGroupDrawer={() =>
                        this.openChoiceGroupDrawer(
                          KitchenMenuChoiceGroupFormDrawerMode.Add
                        )
                      }
                      closeChoiceGroupDrawer={this.closeChoiceGroupDrawer}
                      createChoiceGroup={this.createChoiceGroup}
                      openEditChoiceGroupDrawer={this.openEditChoiceGroupDrawer}
                      editChoiceGroup={this.editChoiceGroup}
                    />
                  );

                case MenuTab.SeeChanges:
                  return (
                    <KitchenMenuManagementSeeChanges
                      data-test-id="kitchen-menu-management-see-changes"
                      seeChangesData={seeChangesData}
                      showNotification={showNotification}
                      pagination={pagination}
                      updateSeeChangesDataStatus={
                        this.updateSeeChangesDataStatus
                      }
                      changePage={this.changePage}
                      toggleShowNotification={this.toggleShowNotification}
                    />
                  );

                default:
                  return <></>;
              }
            })()}
          </Box>
        </Box>

        <CustomSnackbar
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.open}
          type={snackbar.type}
          message={snackbar.message}
          onClose={this.closeSnackbar}
        />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(withTranslation()(KitchenMenuManagement));
// Customizable Area End
