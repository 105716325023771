import moment from "moment";
import "moment/locale/ar";

const toRem = (size: number) => `${size / 16}rem`;

const symbolMap: Record<string, string> = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "0": "0",
};
const numberMap: Record<string, string> = {
  "١": "1",
  "٢": "2",
  "٣": "3",
  "٤": "4",
  "٥": "5",
  "٦": "6",
  "٧": "7",
  "٨": "8",
  "٩": "9",
  "٠": "0",
};

const updateLocaleObject = {
  preparse: function (string: string) {
    return string
      .replace(/\u200f/g, "")
      .replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (match: any) {
        return numberMap[match];
      })
      .replace(/،/g, ",");
  },
  postformat: function (string: string) {
    return string
      .replace(/\d/g, function (match) {
        return symbolMap[match];
      })
      .replace(/,/g, "،");
  },
};

moment.updateLocale("ar", updateLocaleObject);

const customLocaleMoment = moment;

export { toRem, customLocaleMoment, updateLocaleObject };
