// Customizable Area Start
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import { WithStyles } from "@material-ui/core";
import { CustomFilterMenu } from "../../../components/src/CustomFilter.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { WithTranslation } from "react-i18next";

export const configJSON = require("./config");

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

export enum TAB_FINANCE_VIEW {
  INVOICES = 0,
  ARCHIVE = 1,
}

export type Invoice = {
  id: string;

  type: "payment_admin";
  attributes: {
    id: number;
    invoice_number: string;
    is_archived: boolean;
    branch_name: string;
    status: string;
    status_value: string;
    formatted_month: string;
    amount: string;
    amount_value: number;
    invoice_pdf_url: string;
  };
};

export type InvoiceDetails = {
  payment_admin: {
    data: Invoice;
  };
  orders: {
    order_number: string;
    payment_status: string;
    sub_total_price: string;
    total: string;
    created_at: string;
    catalogues: string;
  }[];
};

interface S {
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  tabActive: TAB_FINANCE_VIEW;
  invoices: Invoice[];
  filterYear: string;
  filterYearList: CustomFilterMenu[];
  paymentInvoice: Invoice | null;
  archiveInvoice: Invoice | null;
  loading: boolean;
  invoiceDetailsId: string;
  invoiceDetails: InvoiceDetails | null;
}

interface SS {}

export default class Paymentadmin2FinanceViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetFinances = "";
  apiGetFinanceDetails = "";
  apiPatchFinanceDetails = "";
  apiGetYears = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      pagination: {
        limit: 5,
        page: 1,
        totalCount: 1,
        totalPages: 1,
      },
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
      tabActive: TAB_FINANCE_VIEW.INVOICES,
      invoices: [],
      filterYear: "All",
      filterYearList: [{ id: "0", name: "All", value: "All" }],
      paymentInvoice: null,
      archiveInvoice: null,
      loading: false,
      invoiceDetailsId: "",
      invoiceDetails: null,
    };
  }

  getInvoices = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFinances = requestMessage.messageId;

    const params = new URLSearchParams();
    params.set("page", this.state.pagination.page.toString());
    params.set("per_page", this.state.pagination.limit.toString());
    params.set(
      "archived",
      `${this.state.tabActive === TAB_FINANCE_VIEW.ARCHIVE}`
    );
    params.set(
      "year",
      this.state.filterYear === "All" ? "" : this.state.filterYear
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.financeAPIendPoint + "?" + params.toString()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetInvoices = (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson && responseJson.data) {
      this.setState({
        invoices: responseJson.data,
        pagination: {
          ...this.state.pagination,
          page: responseJson.pagination.current_page,
          totalCount: responseJson.pagination.total_count,
          totalPages: responseJson.pagination.total_pages,
        },
      });
    }

    if (responseJson && (responseJson.errors || responseJson.error)) {
      this.setState({ archiveInvoice: null }, () => {
        this.openSnackbar(
          CustomSnackbarType.Error,
          this.props.t("Error! Please try again!")
        );
      });
    }
  };

  getInvoiceDetails = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFinanceDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.financeAPIendPoint +
        "/" +
        this.state.invoiceDetailsId +
        "/fetch_invoice"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "post"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetInvoiceDetails = (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson && responseJson.payment_admin) {
      this.setState({ invoiceDetails: responseJson });
    }

    if (responseJson && (responseJson.errors || responseJson.error)) {
      this.setState({ archiveInvoice: null }, () => {
        this.openSnackbar(
          CustomSnackbarType.Error,
          this.props.t("Error! Please try again!")
        );
      });
    }
  };

  archiveInvoice = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPatchFinanceDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.financeAPIendPoint +
        "/" +
        this.state.archiveInvoice?.id +
        "/update_archived_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "patch"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        is_archived: !this.state.archiveInvoice?.attributes.is_archived,
      })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleArchiveInvoice = (responseJson: any) => {
    this.setState({ loading: false });

    if (
      responseJson &&
      responseJson.message === "Archived status updated successfully"
    ) {
      this.setState({ archiveInvoice: null }, () => {
        this.openSnackbar(
          CustomSnackbarType.Success,
          this.props.t("Archived status updated successfully")
        );
        this.getInvoices();
      });
    }

    if (responseJson && (responseJson.errors || responseJson.error)) {
      this.setState({ archiveInvoice: null }, () => {
        this.openSnackbar(
          CustomSnackbarType.Error,
          this.props.t("Error! Please try again!")
        );
      });
    }
  };

  getYears = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetYears = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.availableYearsAPIendPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetYears = (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson && responseJson.years) {
      const updatedYearsList = responseJson.years.map(
        (year: number, index: number) => {
          return {
            id: index + 1,
            value: year.toString(),
            name: year.toString(),
          };
        }
      );
      this.setState({
        filterYearList: updatedYearsList,
      });
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiGetFinances:
          this.handleGetInvoices(responseJson);
          break;

        case this.apiGetFinanceDetails:
          this.handleGetInvoiceDetails(responseJson);
          break;

        case this.apiPatchFinanceDetails:
          this.handleArchiveInvoice(responseJson);
          break;

        case this.apiGetYears:
          this.handleGetYears(responseJson);
          break;
      }
    }
  }

  async componentDidMount() {
    this.getInvoices();
    this.getYears();
  }

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
    });
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.getInvoices();
      }
    );
  };

  showDownloadInvoceDetails = (details: Invoice) => {
    this.setState({ invoiceDetailsId: details.id }, () => {
      this.getInvoiceDetails();
    });
  };
  hideDownloadInvoceDetails = () => {
    this.setState({ invoiceDetailsId: "" });
  };

  showPaymentInvoce = (invoce: Invoice) => {
    this.setState({ paymentInvoice: invoce });
  };
  hidePaymentInvoce = () => {
    this.setState({ paymentInvoice: null });
  };

  showArchiveInvoce = (invoce: Invoice) => {
    this.setState({ archiveInvoice: invoce });
  };
  hideArchiveInvoce = () => {
    this.setState({ archiveInvoice: null });
  };

  changeTab = (tab: TAB_FINANCE_VIEW) => {
    this.setState(
      {
        tabActive: tab,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
        filterYear: "All",
      },
      () => {
        this.getInvoices();
      }
    );
  };

  handleArchiveSubmission = () => {
    this.archiveInvoice();
  };

  handleDropdownChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    this.setState({ filterYear: event.target.value as string }, () => {
      this.getInvoices();
    });
  };
}

// Customizable Area End
