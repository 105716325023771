import React from "react";
// Customizable Area Start
import {
  Box,
  withStyles,
  createStyles,
  InputAdornment,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Theme,
} from "@material-ui/core";
import KitchenOrderManagementController, {
  KitchenOrderDrawerType,
  Props,
} from "./KitchenOrderManagementController.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomInputField from "../../../components/src/CustomInputField.web";
import { Close, SaveAlt, Search } from "@material-ui/icons";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomTable from "../../../components/src/CustomTable";
import KitchenOrderTableRow from "../../../components/src/KitchenOrderTableRow.web";
import KitchenOrderSummaryDrawer from "../../../components/src/KitchenOrderSummaryDrawer.web";
import KitchenOrderChangeDrawer from "../../../components/src/KitchenOrderChangeDrawer.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "&.rtl": {
        direction: "rtl",
      },
    },

    actions: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        gap: 10,
      },
    },

    filter: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      flexWrap: "wrap",
    },

    filterSelect: {
      width: "100%",
    },

    filterSelectStatus: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 3,
        flex: 1,
      },
    },

    filterSelectTime: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 2,
        flex: 1,
      },
    },

    downloadButton: {
      marginLeft: "auto",

      "&.rtl": {
        marginLeft: 0,
        marginRight: "auto",
      },
    },

    orderData: {
      marginTop: 24,
    },

    pagination: {
      marginTop: 24,
      display: "flex",
      justifyContent: "end",
    },

    searchOrderId: {
      "& input": {
        fontFamily: "Inter !important",
      },

      [theme.breakpoints.down("md")]: {
        order: 1,
        width: "100%",
      },
    },

    orderTable: {
      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },
    },
  });
// Customizable Area End

export class KitchenOrderManagement extends KitchenOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, t, i18n } = this.props;

    const {
      orderIdSearch,
      loading,
      timeFilter,
      statusFilter,
      orders,
      orderDrawer,
      orderStatusOptions,
      statusFilterOptions,
      pagination,
    } = this.state;

    const rtl = i18n.language === "ar";

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <CustomLoadingBackdrop open={loading} />

        <Box className={classes.actions}>
          <Box className={classes.filter}>
            <Box className={classes.filterSelectTime}>
              <CustomSelect
                dir={rtl ? "rtl" : ""}
                className={classes.filterSelect}
                data-test-id="time-select"
                variant="outlined"
                value={timeFilter}
                onChange={(e: any) => this.changeTimeFilter(e.target.value)}
              >
                {this.timeOptions.map((option) => (
                  <CustomMenuItem value={option.value} key={option.id}>
                    {t(option.name)}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <Box className={classes.filterSelectStatus}>
              <CustomSelect
                dir={rtl ? "rtl" : ""}
                className={classes.filterSelect}
                data-test-id="status-select"
                placeholder={t("Order Status")}
                variant="outlined"
                value={statusFilter}
                onChange={(e: any) => this.changeStatusFilter(e.target.value)}
              >
                {statusFilterOptions.map((option) => (
                  <CustomMenuItem value={option.value} key={option.id}>
                    {t(option.name)}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <CustomInputField
              data-test-id="search-order-id-input"
              className={classes.searchOrderId}
              inputRef={this.inputRef}
              variant="outlined"
              placeholder={t("Order ID")}
              InputProps={{
                endAdornment: (
                  <>
                    {orderIdSearch && (
                      <IconButton
                        data-test-id="clear-search-order-id-btn"
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={this.clearSearchOrderId}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  </>
                ),
              }}
              onKeyDown={this.handleSearch}
            />
          </Box>

          <CustomButton
            className={clsx(classes.downloadButton, { ["rtl"]: rtl })}
            startIcon={<SaveAlt />}
            variant="contained"
            color="primary"
            data-test-id="download-order-report-btn"
            onClick={this.downloadReport}
          >
            {t("Download Report")}
          </CustomButton>
        </Box>

        <Box className={classes.orderData}>
          <TableContainer>
            <CustomTable className={classes.orderTable}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell>{t("Order ID")}</TableCell>
                  <TableCell>{t("Payment status")}</TableCell>
                  <TableCell>{t("Restaurant Name")}</TableCell>
                  <TableCell>{t("Order Status")}</TableCell>
                  <TableCell>{t("Order Type")}</TableCell>
                  <TableCell>{t("Subtotal")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {orders.length ? (
                  orders.map((order) => (
                    <KitchenOrderTableRow
                      data-test-id={`table-row-${order.id}`}
                      key={order.id}
                      order={order}
                      orderStatusOptions={orderStatusOptions}
                      onChangeAcceptStatus={this.onChangeAcceptStatus}
                      openOrderSummaryDrawer={this.openOrderSummaryDrawer}
                      onAcceptOrder={this.onAcceptOrder}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box style={{ textAlign: "center" }}>
                        {t("No orders found")}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <KitchenOrderSummaryDrawer
            data-test-id="order-summary-drawer"
            open={orderDrawer.open === KitchenOrderDrawerType.Summary}
            selectedOrder={orderDrawer.selectedOrder}
            onClose={this.closeOrderDrawer}
            updateOrderItemStatus={this.updateOrderItemStatus}
            openOrderChangeDrawer={this.openOrderChangeDrawer}
          />

          <KitchenOrderChangeDrawer
            data-test-id="order-change-drawer"
            open={orderDrawer.open === KitchenOrderDrawerType.ChangeItem}
            selectedOrder={orderDrawer.selectedOrder}
            categories={orderDrawer.categories}
            loading={orderDrawer.loading}
            addCatalogue={this.addCatalogue}
            onClose={this.closeOrderDrawer}
            onBack={this.closeOrderChangeDrawer}
          />

          <Box className={classes.pagination}>
            <CustomPagination
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(_, page) => this.changePage(page)}
              showOffset
            />
          </Box>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(withTranslation()(KitchenOrderManagement));
// Customizable Area End
