import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { CustomFilterMenu } from "../../../components/src/CustomFilter.web";
import { TFunction, i18n } from "i18next";

enum TABS {
  SALES = 0,
  OPERATIONS = 1,
  CUSTOMERS = 2,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: TFunction<"translation", undefined>
  i18n: i18n
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabActive: TABS;
  tabs: { value: TABS; name: string }[];
  filterTime: string;
  filterTimeList: CustomFilterMenu[];
  txtInputValue: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      tabActive: TABS.SALES,
      tabs: [
        { value: TABS.SALES, name: "Sales" },
        { value: TABS.OPERATIONS, name: "Operations" },
        { value: TABS.CUSTOMERS, name: "Customers" },
      ],
      filterTime: "m",
      filterTimeList: [
        { id: "0", name: "Weekly", value: "w" },
        { id: "1", name: "Monthly", value: "m" },
      ],
      txtInputValue: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  getDir = () => {
    return this.isRTL() ? "rtl" : "ltr";
  };

  isRTL = () => {
    return this.props.i18n.language === "ar";
  };
  // Customizable Area End
}
