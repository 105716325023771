import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import {
  AdminOrderDetailFormDialogMode,
  AdminOrderDetailFormDialogProps,
} from "../../../components/src/AdminOrderDetailFormDialog.web";
import moment from "moment";
import {
  AdminOrderManagementAction,
  AdminOrderManagementMenuAction,
} from "../../../components/src/AdminOrderManagementMenu.web";
import { AdminOrderDeleteDialogProps } from "../../../components/src/AdminOrderDeleteDialog.web";
import { WithTranslation } from "react-i18next";

export enum GetAdminDetailOrderTask {
  Detail = "Detail",
  Status = "Status",
  Branch = "Branch",
}

export interface AdminOrder {
  id: string | number;
  orderNumber: string;
  restaurantName: string;
  branch: string;
  branchId: string | number;
  datePlaced: string | number | Date;
  totalAmount: number;
  status: string;
  kitchenId: string | number;
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  orderDetailDialog: AdminOrderDetailFormDialogProps;
  orderDeleteDialog: AdminOrderDeleteDialogProps;
  orders: AdminOrder[];
  loading: boolean;
  orderIdSearch: string;
  timeFilter: number;
  statusFilter: string | number;
  statusFilterOptions: {
    id: string;
    name: string;
    value: string;
  }[];
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  orderStatusOptions: {
    id: string;
    name: string;
    value: number | string;
  }[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminOrderManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchAdminOrdersApiId: string = "";
  downloadAdminReportApiId: string = "";
  fetchAdminOrderStatusesApiId: string = "";
  editAdminOrderApiId: string = "";
  deleteAdminOrderApiId: string = "";
  getAdminDetailOrderApi: {
    [id: string]: {
      task: GetAdminDetailOrderTask;
      message: Message;
    };
  } = {};

  timeOptions = [
    {
      id: uuidv4(),
      name: "Last 7 days",
      value: 7,
    },
    {
      id: uuidv4(),
      name: "Current day",
      value: 1,
    },
  ];

  inputRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.inputRef = React.createRef();

    this.state = {
      orderDetailDialog: {
        mode: AdminOrderDetailFormDialogMode.View,
        form: {
          id: "",
          orderNumber: "",
          restaurantName: "",
          branch: "",
          datePlaced: null,
          totalAmount: 0,
          status: "",
        },
        open: false,
        loading: false,
        branchOptions: [],
        statusOptions: [],
      },
      orderDeleteDialog: {
        open: false,
        order: {
          id: "",
          orderNumber: "",
          restaurantName: "",
          branch: "",
          branchId: "",
          datePlaced: "",
          totalAmount: 0,
          status: "",
          kitchenId: "",
        },
      },
      orders: [],
      pagination: {
        page: 1,
        limit: 12,
        totalPages: 0,
        totalCount: 0,
      },
      timeFilter: 7,
      orderIdSearch: "",
      loading: false,
      statusFilter: "all",
      statusFilterOptions: [
        {
          id: uuidv4(),
          name: "Order Status",
          value: "all",
        },
      ],
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
      orderStatusOptions: [],
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchAdminOrderStatuses();
    this.fetchAdminOrders();
    // Customizable Area End
  }

  // Customizable Area Start
  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  changeTimeFilter = (timeFilter: number) => {
    this.setState(
      {
        timeFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminOrders();
      }
    );
  };

  changeStatusFilter = (statusFilter: string | number) => {
    this.setState(
      {
        statusFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminOrders();
      }
    );
  };

  handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    const target = event.target as HTMLInputElement;

    this.setState(
      {
        orderIdSearch: target.value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminOrders();
      }
    );
  };

  clearSearchOrderId = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }

    this.setState(
      {
        orderIdSearch: "",
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminOrders();
      }
    );
  };

  downloadAdminReport = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downloadAdminReportApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/generate_pdf.pdf`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleDownloadAdminReport = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        "Update order item status failed! Please try again"
      );

      return;
    }

    const link = document.createElement("a");
    link.href = responseJson.download_url;
    link.download = "order-report.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  createAdminOrderMessage = async (messageData: {
    url: string;
    method: string;
    body?: any;
  }) => {
    const token = await StorageProvider.get("token");

    const header = { "Content-Type": "application/json", token };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      messageData.url
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      messageData.method
    );

    if (messageData.body) {
      message.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(messageData.body)
      );
    }

    return message;
  };

  openOrderDeleteDialog = () => {
    this.setState({
      orderDeleteDialog: {
        ...this.state.orderDeleteDialog,
        open: true,
      },
    });
  };

  closeOrderDeleteDialog = () => {
    this.setState({
      orderDeleteDialog: {
        open: false,
        order: {
          id: "",
          orderNumber: "",
          restaurantName: "",
          branch: "",
          branchId: "",
          datePlaced: "",
          totalAmount: 0,
          status: "",
          kitchenId: "",
        },
      },
    });
  };

  onSelectAction = async (
    order: AdminOrder,
    action: AdminOrderManagementMenuAction
  ) => {
    if (action === AdminOrderManagementAction.Delete) {
      this.setState(
        {
          orderDeleteDialog: {
            ...this.state.orderDeleteDialog,
            order,
          },
        },
        () => this.openOrderDeleteDialog()
      );

      return;
    }

    const detailMessage = await this.createAdminOrderMessage({
      url: `/bx_block_order_management/orders/${order.id}`,
      method: "GET",
    });

    const statusOptionsMessage = await this.createAdminOrderMessage({
      url: `bx_block_order_management/order_statuses`,
      method: "GET",
    });

    const branchOptionsMessage = await this.createAdminOrderMessage({
      url: `bx_block_location/stores/find_stores?kitchen_account_id=${order.kitchenId}`,
      method: "GET",
    });

    this.getAdminDetailOrderApi = {
      [detailMessage.messageId]: {
        task: GetAdminDetailOrderTask.Detail,
        message: detailMessage,
      },

      [statusOptionsMessage.messageId]: {
        task: GetAdminDetailOrderTask.Status,
        message: statusOptionsMessage,
      },

      [branchOptionsMessage.messageId]: {
        task: GetAdminDetailOrderTask.Branch,
        message: branchOptionsMessage,
      },
    };

    Object.keys(this.getAdminDetailOrderApi).forEach((key) => {
      runEngine.sendMessage(key, this.getAdminDetailOrderApi[key].message);
    });

    this.setState({
      loading: true,
      orderDetailDialog: {
        ...this.state.orderDetailDialog,
        mode: action,
        form: {
          ...this.state.orderDetailDialog.form,
          id: order.id,
        },
      },
    });
  };

  deleteAdminOrder = async () => {
    const declinedStatus = this.state.statusFilterOptions.find(
      (option) => option.name === "Declined"
    );

    if (!declinedStatus) {
      return;
    }

    const { order } = this.state.orderDeleteDialog;

    const message = await this.createAdminOrderMessage({
      method: "PATCH",
      url: `/bx_block_order_management/orders/${order.id}`,
      body: {
        order: {
          restaurant_name: order.restaurantName,
          branch_id: order.branchId,
          created_at: moment(order.datePlaced).format("YYYY-MM-DD"),
          total: order.totalAmount,
          status_id: declinedStatus.id,
        },
      },
    });

    this.deleteAdminOrderApiId = message.messageId;

    runEngine.sendMessage(message.messageId, message);

    this.setState({
      loading: true,
    });
  };

  handleDeleteAdminOrder = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    this.closeOrderDeleteDialog();

    if (responseJson.errors) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    if (responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, responseJson.error);

      return;
    }

    this.openSnackbar(CustomSnackbarType.Success, "Order deleted successfully");

    this.fetchAdminOrders();
  };

  hanldeGetAdminOrderDetail = (
    task: GetAdminDetailOrderTask,
    responseJson: any
  ) => {
    if (responseJson.errors || responseJson.error) {
      this.getAdminDetailOrderApi = {};
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    switch (task) {
      case GetAdminDetailOrderTask.Status:
        const statusOptions = responseJson.map(
          (status: { id: string | number; name: string }) => ({
            id: status.id,
            name: status.name,
            value: status.id.toString(),
          })
        );

        this.setState({
          orderDetailDialog: {
            ...this.state.orderDetailDialog,
            statusOptions,
          },
        });

        break;

      case GetAdminDetailOrderTask.Branch:
        const branchOptions = responseJson.data.map((branch: any) => ({
          id: branch.id,
          name: branch.attributes.name,
          value: branch.id.toString(),
        }));

        this.setState({
          orderDetailDialog: {
            ...this.state.orderDetailDialog,
            branchOptions,
          },
        });

        break;

      case GetAdminDetailOrderTask.Detail:
        this.setState({
          orderDetailDialog: {
            ...this.state.orderDetailDialog,
            form: {
              ...this.state.orderDetailDialog.form,
              orderNumber: responseJson.order_number,
              restaurantName: responseJson.restaurant_name,
              branch: responseJson.branch_id?.toString() || "",
              status: responseJson.status_id?.toString() || "",
              totalAmount: responseJson.total_amount,
              datePlaced: moment(responseJson.date_placed).toDate(),
            },
          },
        });
        break;

      default:
        break;
    }

    if (Object.keys(this.getAdminDetailOrderApi).length) {
      return;
    }

    this.setState({
      loading: false,
    });

    this.openOrderDetailDialog();
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchAdminOrders();
      }
    );
  };

  fetchAdminOrders = async () => {
    const requestMessage = await this.createAdminOrderMessage({
      url: `/bx_block_order_management/orders/all_orders?&order_id=${
        this.state.orderIdSearch
      }&per_page=${this.state.pagination.limit}&page=${
        this.state.pagination.page
      }&days_ago=${this.state.timeFilter}&order_status_id=${
        this.state.statusFilter === "all" ? "" : this.state.statusFilter
      }`,
      method: "GET",
    });

    this.fetchAdminOrdersApiId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchAdminOrders = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const orders = responseJson.data.map((order: any) => {
      const {
        id,
        order_number,
        created_at,
        total,
        restaurant_name,
        branch_name,
        status,
        kitchen_id,
        branch_id,
      } = order.attributes;

      return {
        id: id,
        orderNumber: order_number,
        restaurantName: restaurant_name,
        branch: branch_name,
        datePlaced: created_at,
        totalAmount: total,
        status: status.name,
        kitchenId: kitchen_id,
        branchId: branch_id,
      };
    });

    const pagination = {
      limit: 12,
      page: responseJson.pagination.current_page,
      totalCount: responseJson.pagination.total_count,
      totalPages: responseJson.pagination.total_pages,
    };

    this.setState({
      orders,
      pagination,
    });
  };

  fetchAdminOrderStatuses = async () => {
    const requestMessage = await this.createAdminOrderMessage({
      url: `bx_block_order_management/order_statuses`,
      method: "GET",
    });

    this.fetchAdminOrderStatusesApiId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFetchAdminOrderStatuses = async (responseJson: any) => {
    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        "Fetch order statuses failed! Please try again"
      );

      return;
    }

    const orderStatusOptions = responseJson.map(
      (status: { id: string | number; name: string }) => ({
        id: status.id,
        name: status.name,
        value: status.id,
      })
    );

    const statusFilterOptions = JSON.parse(
      JSON.stringify(this.state.statusFilterOptions)
    );

    orderStatusOptions.forEach(
      (option: {
        id: string | number;
        name: string;
        value: string | number;
      }) => {
        statusFilterOptions.push(option);
      }
    );

    this.setState({
      orderStatusOptions,
      statusFilterOptions,
    });
  };

  openOrderDetailDialog = () => {
    this.setState({
      orderDetailDialog: {
        ...this.state.orderDetailDialog,
        open: true,
      },
    });
  };

  changeOrderDetailDialogMode = (mode: AdminOrderDetailFormDialogMode) => {
    this.setState({
      orderDetailDialog: {
        ...this.state.orderDetailDialog,
        mode,
      },
    });
  };

  closeOrderDetailDialog = () => {
    this.setState({
      orderDetailDialog: {
        mode: AdminOrderDetailFormDialogMode.View,
        form: {
          id: "",
          orderNumber: "",
          restaurantName: "",
          branch: "",
          datePlaced: null,
          totalAmount: 0,
          status: "",
        },
        open: false,
        loading: false,
        branchOptions: [],
        statusOptions: [],
      },
    });
  };

  editAdminOrder = async (values: {
    orderNumber: string;
    restaurantName: string;
    branch: string;
    datePlaced: Date;
    totalAmount: number;
    status: string;
  }) => {
    const message = await this.createAdminOrderMessage({
      method: "PATCH",
      url: `/bx_block_order_management/orders/${this.state.orderDetailDialog.form.id}`,
      body: {
        order: {
          restaurant_name: values.restaurantName,
          branch_id: values.branch,
          created_at: moment(values.datePlaced).format("YYYY-MM-DD"),
          total: values.totalAmount,
          status_id: values.status,
        },
      },
    });

    this.editAdminOrderApiId = message.messageId;

    runEngine.sendMessage(message.messageId, message);

    this.setState({
      loading: true,
    });
  };

  handleEditAdminOrder = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    if (responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, responseJson.error);

      return;
    }

    this.fetchAdminOrders();
    this.closeOrderDetailDialog();
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      Object.keys(this.getAdminDetailOrderApi).length &&
      Object.keys(this.getAdminDetailOrderApi).includes(apiRequestCallId)
    ) {
      const task = this.getAdminDetailOrderApi[apiRequestCallId].task;

      delete this.getAdminDetailOrderApi[apiRequestCallId];

      this.hanldeGetAdminOrderDetail(task, responseJson);
    }

    switch (apiRequestCallId) {
      case this.fetchAdminOrdersApiId:
        this.handleFetchAdminOrders(responseJson);

        break;

      case this.fetchAdminOrderStatusesApiId:
        this.handleFetchAdminOrderStatuses(responseJson);
        break;

      case this.downloadAdminReportApiId:
        this.handleDownloadAdminReport(responseJson);

        break;

      case this.editAdminOrderApiId:
        this.handleEditAdminOrder(responseJson);

        break;

      case this.deleteAdminOrderApiId:
        this.handleDeleteAdminOrder(responseJson);

        break;

      default:
        break;
    }
    // Customizable Area End
  }
}
