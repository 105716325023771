// Customizable Area Start
import React from "react";

import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

import CustomPagination from "../../../components/src/CustomPagination";
import CustomSnackbarWeb from "../../../components/src/CustomSnackbar.web";

import Paymentadmin2FinanceViewController, {
  Invoice,
  Props,
  TAB_FINANCE_VIEW,
} from "./Paymentadmin2FinanceViewController.web";
import CustomTabs from "../../../components/src/CustomTabs";
import {
  IconButton,
  Portal,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CustomTable from "../../../components/src/CustomTable";
import clsx from "clsx";
import { icArchiveFiles, icDownload, icEyeOutline } from "./assets";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomMenuItemWeb from "../../../components/src/CustomMenuItem.web";
import CustomPaymentadmin2FinaceViewWeb from "../../../components/src/paymentadmin2/CustomPaymentadmin2FinaceView.web";
import CustomPaymentadmin2FinacePaymentWeb from "../../../components/src/paymentadmin2/CustomPaymentadmin2FinacePayment.web";
import CustomPaymentadmin2FinanceArchiveWeb from "../../../components/src/paymentadmin2/CustomPaymentadmin2FinanceArchive.web";
import CustomLoadingBackdropWeb from "../../../components/src/CustomLoadingBackdrop.web";
import { withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    textRight: {
      textAlign: "end",
    },
    table: {
      "& .table__action": {
        display: "flex",
      },

      "& .font-inter": {
        fontFamily: "Inter",
      },

      "& .table__cell__month": {
        textTransform: "uppercase",
      },

      "& .table__cell__status": {
        color: "#FFF",
        fontWeight: 700,
        borderRadius: 8,
        padding: "3px 14px",
        height: 30,
        maxWidth: 97,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&[data-status='archived']": {
          backgroundColor: " #F1F5F9",
          color: "#333",
        },
        "&[data-status='in_process'], &[data-status='half_paid']": {
          backgroundColor: "#F59E0B",
        },
        "&[data-status='due_in_2_days']": {
          backgroundColor: "#F87171",
        },
        "&[data-status='overdue']": {
          backgroundColor: "#DC2626",
        },
        "&[data-status='paid']": {
          backgroundColor: "#059669",
        },
      },
      "& .table__cell_empty": {
        textAlign: "center",
        lineHeight: 4,

        [theme.breakpoints.down("md")]: {
          textAlign: "left",
        },
      },
    },
    pagination: {
      display: "flex",
      justifyContent: "end",
      marginTop: "auto",
    },
    filterSelect: {
      width: 116,
    },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    content: {
      padding: 12,
      borderRadius: 8,
      border: "1px solid #EDEDED",
      borderTopLeftRadius: 0,
      minHeight: 640,
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
  });

export class Paymentadmin2FinanceView extends Paymentadmin2FinanceViewController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;
    const {
      pagination,
      snackbar,
      tabActive,
      invoices,
      filterYear,
      filterYearList,
      paymentInvoice,
      archiveInvoice,
      loading,
      invoiceDetails,
      invoiceDetailsId,
    } = this.state;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    return (
      <>
        <Portal>
          <CustomLoadingBackdropWeb open={loading} />
        </Portal>

        <CustomTabs
          variant="scrollable"
          scrollButtons="auto"
          value={tabActive}
          dir={dir}
        >
          <Tab
            data-test-id="tabInvoices"
            label={t("Invoices")}
            value={TAB_FINANCE_VIEW.INVOICES}
            onClick={() => {
              this.changeTab(TAB_FINANCE_VIEW.INVOICES);
            }}
          />
          <Tab
            data-test-id="tabArchive"
            label={t("Archive")}
            value={TAB_FINANCE_VIEW.ARCHIVE}
            onClick={() => {
              this.changeTab(TAB_FINANCE_VIEW.ARCHIVE);
            }}
          />
        </CustomTabs>

        <div className={classes.content}>
          <div className={classes.actions} dir={dir}>
            <CustomSelect
              id="select-rating"
              data-test-id="select-rating"
              className={classes.filterSelect}
              variant="outlined"
              value={filterYear}
              placeholder={t("Rating level")}
              onChange={this.handleDropdownChange}
              dir={dir}
            >
              <CustomMenuItemWeb
                className={classes.languageMenuItem}
                value={"All"}
                dir={dir}
              >
                {t("All")}
              </CustomMenuItemWeb>

              {filterYearList.map((option) => (
                <CustomMenuItemWeb
                  className={classes.languageMenuItem}
                  value={option.value}
                  key={option.id}
                  dir={dir}
                >
                  {option.name}
                </CustomMenuItemWeb>
              ))}
            </CustomSelect>
          </div>

          <TableContainer>
            <CustomTable className={classes.table} dir={dir}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Invoice Number")}</TableCell>
                  <TableCell>{t("Branch")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell>{t("Month")}</TableCell>
                  <TableCell className={classes.textRight}>
                    {t("Amount")}
                  </TableCell>
                  <TableCell className={classes.textRight}>
                    {t("Action")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {invoices.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} className="table__cell_empty">
                      {t("No invoices")}
                    </TableCell>
                  </TableRow>
                )}
                {invoices.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell className={"font-inter"}>
                      {invoice.attributes.invoice_number}
                    </TableCell>
                    <TableCell>{invoice.attributes.branch_name}</TableCell>
                    <TableCell>
                      <div
                        className="table__cell__status"
                        data-status={
                          invoice.attributes.is_archived
                            ? "archived"
                            : invoice.attributes.status_value
                        }
                      >
                        {invoice.attributes.is_archived
                          ? t("Archived")
                          : t(invoice.attributes.status_value)}
                      </div>
                    </TableCell>
                    <TableCell className={"font-inter table__cell__month"}>
                      {invoice.attributes.formatted_month}
                    </TableCell>
                    <TableCell
                      className={clsx(classes.textRight, "font-inter")}
                    >
                      {invoice.attributes.amount}
                    </TableCell>
                    <TableCell className={classes.textRight}>
                      <IconButton>
                        <img src={icDownload} />
                      </IconButton>
                      <IconButton
                        data-testid="btnShowDownloadInvoceDetails"
                        onClick={() => this.showDownloadInvoceDetails(invoice)}
                      >
                        <img src={icEyeOutline} />
                      </IconButton>
                      {!invoice.attributes.is_archived && (
                        <IconButton
                          data-testid="btnShowArchiveInvoce"
                          onClick={() => {
                            this.showArchiveInvoce(invoice);
                          }}
                        >
                          <img src={icArchiveFiles} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <div className={classes.pagination} dir={dir}>
            <CustomPagination
              page={pagination.page}
              total={pagination.totalCount}
              count={pagination.totalPages}
              limit={pagination.limit}
              variant="text"
              data-testid="pagination"
              onChange={(event, page) => {
                this.changePage(page);
              }}
              showOffset
            />
          </div>
        </div>

        <CustomSnackbarWeb
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.open}
          type={snackbar.type}
          message={snackbar.message}
          onClose={this.closeSnackbar}
          dir={dir}
        />

        <CustomPaymentadmin2FinaceViewWeb
          invoice={invoiceDetails}
          open={Boolean(invoiceDetailsId) && !loading}
          onClose={this.hideDownloadInvoceDetails}
          onPay={() => {}}
        />

        <CustomPaymentadmin2FinacePaymentWeb
          invoice={paymentInvoice}
          open={Boolean(paymentInvoice)}
          onClose={this.hidePaymentInvoce}
        />

        {/* Archive Dialog */}

        <CustomPaymentadmin2FinanceArchiveWeb
          invoice={archiveInvoice}
          open={Boolean(archiveInvoice)}
          onClose={this.hideArchiveInvoce}
          onSubmit={this.handleArchiveSubmission}
        />
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(Paymentadmin2FinanceView));
// Customizable Area End
