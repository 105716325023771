import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { RefObject } from "react";
import CustomButton from "./CustomButton.web";
import KitchenDrawer from "./KitchenDrawer.web";
import CustomFormInputField from "./CustomFormInputField.web";
import CustomFormSelectField from "./CustomFormSelectField.web";
import CustomFormSingleImageUpload from "./CustomFormSingleImageUpload.web";
import * as Yup from "yup";
import { WithTranslation, withTranslation } from "react-i18next";

export enum KitchenMenuCategoryFormDrawerMode {
  Add = "add",
  Edit = "edit",
}

export interface KitchenMenuCategoryFormDrawerProps {
  mode: KitchenMenuCategoryFormDrawerMode;
  open: boolean;
  loading: boolean;
  options: {
    id: string | number;
    name: string;
    value: string | number;
  }[];
  form: {
    id: string | number;
    name: string;
    image: File | null | string;
    categories: string[];
  };
}

export interface Props extends WithStyles, WithTranslation {
  drawer: KitchenMenuCategoryFormDrawerProps;

  onClose(): void;
  createCategory(values: {
    name: string;
    image: File | string | null;
    categories: string[];
  }): void;
  editCategory(values: {
    name: string;
    image: File | string | null;
    categories: string[];
  }): void;
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
    },

    formBottom: {
      marginTop: "auto",
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },
  });

export class KitchenMenuCategoryFormDrawer extends React.Component<Props> {
  private submitForm: RefObject<() => void>;

  constructor(props: Props) {
    super(props);

    this.submitForm = React.createRef();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render(): React.ReactNode {
    const {
      classes,
      drawer,
      t,
      i18n,

      onClose,
      createCategory,
      editCategory,
    } = this.props;

    const { open, loading, options, mode, form } = drawer;

    const rtl = i18n.language === "ar";

    const validationSchema = Yup.object({
      name: Yup.string().nullable().required(t("Please enter category title")),
      image: Yup.mixed<File>()
        .nullable()
        .required(t("Please attach item photo")),
      categories: Yup.array()
        .of(Yup.string())
        .nullable()
        .required(t("Please enter Main Category Group"))
        .max(3, t("Please select up to 3 categories only")),
    });

    return (
      <KitchenDrawer
        dir={rtl ? "rtl" : ""}
        anchor={rtl ? "left" : "right"}
        open={open}
        title={
          mode === KitchenMenuCategoryFormDrawerMode.Edit
            ? t("Edit Category")
            : t("Add New Category")
        }
        onClose={onClose}
        body={
          <Formik
            enableReinitialize
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              mode === KitchenMenuCategoryFormDrawerMode.Edit
                ? editCategory(values)
                : createCategory(values);
            }}
            data-test-id="kitchen-category-formik"
          >
            {(props) => {
              (this.submitForm as React.MutableRefObject<() => void>).current =
                props.handleSubmit;

              return (
                <Form
                  data-test-id="kitchen-category-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <Box className={classes.formInner}>
                    <CustomFormSingleImageUpload
                      placeholder={t("Only allow JPEG and PNG formats")}
                      value={props.values.image}
                      disabled={loading}
                      name="image"
                      label={t("Upload item photo")}
                      error={props.touched.image && !!props.errors.image}
                      data-test-id="image-upload"
                      onChange={(file: File | null) =>
                        props.setFieldValue("image", file)
                      }
                    />

                    <CustomFormInputField
                      disabled={loading}
                      fullWidth
                      name="name"
                      label={t("Add category title")}
                      placeholder={t("Enter category title")}
                      variant="outlined"
                      error={props.touched.name && !!props.errors.name}
                      data-test-id="title-input"
                    />

                    <CustomFormSelectField
                      dir={rtl ? "rtl" : ""}
                      disabled={loading}
                      fullWidth
                      name="categories"
                      label={t("Main Category Group")}
                      subLabel={`(${t("Choose Max {{number}}", {
                        number: 3,
                      })})`}
                      variant="outlined"
                      error={
                        props.touched.categories && !!props.errors.categories
                      }
                      data-test-id="group-select"
                      multiple
                      inputProps={{
                        renderValue: (selected: (string | number)[]) => {
                          if (selected.length === 0) {
                            return t("Select Main Category Group");
                          }

                          const selectedItems = options.filter(
                            (option: {
                              id: string | number;
                              name: string;
                              value: string | number;
                            }) => selected.includes(option.value)
                          );

                          return selectedItems
                            .map((item) => item.name)
                            .join(", ");
                        },
                      }}
                      displayEmpty
                    >
                      {options.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          <Checkbox
                            checked={
                              props.values.categories
                                ? (
                                    props.values.categories as string[]
                                  ).findIndex(
                                    (item) =>
                                      item.toString() ===
                                      option.value.toString()
                                  ) > -1
                                : false
                            }
                          />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </CustomFormSelectField>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        }
        bottom={
          <Box className={classes.formBottom}>
            <CustomButton
              data-test-id="cancel-btn"
              variant="contained"
              color="default"
              onClick={onClose}
              disabled={loading}
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              data-test-id="save-btn"
              color="primary"
              variant="contained"
              type="submit"
              disabled={loading}
              onClick={() =>
                (
                  this.submitForm as React.MutableRefObject<() => void>
                ).current()
              }
            >
              {t("Save")}
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation()(KitchenMenuCategoryFormDrawer)
);
