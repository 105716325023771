import { Box, InputLabel, createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ErrorMessage, Field } from "formik";
import React from "react";
import CustomSingleImageUpload, {
  CustomSingleImageUploadProps,
} from "./CustomSingleImageUpload.web";
import clsx from "clsx";

export interface Props extends WithStyles<any> {
  className?: string;
  name: string;
  error?: boolean;
  label: string;
  disabled?: boolean;
  placeholder?: string;
}

export type CustomFormSingleImageUploadProps = Props &
  CustomSingleImageUploadProps;

const styles = createStyles({
  container: {
    "& + &": {
      marginTop: 24,
    },

    "&.is-disabled": {
      opacity: "0.5",
      pointerEvent: "none",
    },
  },

  label: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "22px",
    color: "#333333",
    fontFamily: "Raleway",
    marginBottom: 10,
  },

  input: {
    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#DC2626",
      fontWeight: 400,
      lineHeight: "18px",
      fontSize: 12,
      fontFamily: "Inter",
    },
  },
});

export class CustomFormSingleImageUpload extends React.Component<CustomFormSingleImageUploadProps> {
  constructor(props: CustomFormSingleImageUploadProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { name, label, classes, error, className, disabled, ...rest } =
      this.props;

    return (
      <Box
        className={clsx(classes.container, className, {
          ["is-disabled"]: disabled,
        })}
      >
        <InputLabel className={classes.label} htmlFor={name}>
          {label}
        </InputLabel>

        <Field
          className={classes.input}
          as={CustomSingleImageUpload}
          name={name}
          error={error}
          helperText={error && <ErrorMessage name={name} />}
          {...rest}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(CustomFormSingleImageUpload);
