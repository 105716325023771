import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithTranslation } from "react-i18next";
import React from "react";
import { WithStyles } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { AdminUserManagementFormDialogProps } from "../../../components/src/AdminUserManagementFormDialog.web";
import { AdminUserManagementAction } from "../../../components/src/AdminUserManagementMenu.web";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles, WithTranslation {
  id: string;
}

export interface AdminUser {
  id: string | number;
  restaurantName: string;
  branch: string;
  staffName: string;
  email: string;
  role: string;
  status: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  users: AdminUser[];

  requestNameSearch: string;

  timeFilter: number;

  roleFilter: string | number;

  roleFilterOptions: {
    id: string;
    name: string;
    value: string;
  }[];

  loading: false;

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  adminUserManagementDialog: AdminUserManagementFormDialogProps;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminAccountManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  timeOptions = [
    {
      id: uuidv4(),
      name: "Last 7 days",
      value: 7,
    },
    {
      id: uuidv4(),
      name: "Current day",
      value: 1,
    },
  ];

  inputRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.inputRef = React.createRef();

    this.state = {
      users: [],

      requestNameSearch: "",

      roleFilter: "",

      roleFilterOptions: [],

      timeFilter: 7,

      loading: false,

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },

      pagination: {
        page: 1,
        limit: 12,
        totalPages: 0,
        totalCount: 0,
      },

      adminUserManagementDialog: {
        mode: AdminUserManagementAction.Add,
        open: false,
        loading: false,

        form: {
          id: "",
          restaurantName: "",
          branch: "",
          staffName: "",
          email: "",
          role: "",
          status: "",
        },

        roleOptions: [],

        branchOptions: [],

        statusOptions: [],
      },
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchAdminRoles();
    this.fetchAdminUsers();
    // Customizable Area End
  }

  // Customizable Area Start
  fetchAdminRoles = () => {
    this.setState({
      roleFilterOptions: [
        {
          id: uuidv4(),
          name: "Admin",
          value: "admin",
        },
        {
          id: uuidv4(),
          name: "Manager",
          value: "manager",
        },
      ],
    });
  };

  fetchAdminUsers = () => {
    this.setState({
      users: [
        {
          id: 1,
          restaurantName: "Burger King",
          branch: "Downtown",
          staffName: "John Doe",
          email: "john@example.com",
          role: "Manager",
          status: "Active",
        },
        {
          id: 2,
          restaurantName: "McDonald's",
          branch: "Uptown",
          staffName: "Jane Smith",
          email: "jane@example.com",
          role: "Assistant Manager",
          status: "Inactive",
        },
        {
          id: 3,
          restaurantName: "Subway",
          branch: "Main St",
          staffName: "Robert Brown",
          email: "robert@example.com",
          role: "Cashier",
          status: "Active",
        },
        {
          id: 4,
          restaurantName: "Pizza Hut",
          branch: "West End",
          staffName: "Emily Davis",
          email: "emily@example.com",
          role: "Chef",
          status: "Active",
        },
        {
          id: 5,
          restaurantName: "Taco Bell",
          branch: "East Side",
          staffName: "Michael Wilson",
          email: "michael@example.com",
          role: "Manager",
          status: "Active",
        },
        {
          id: 6,
          restaurantName: "KFC",
          branch: "Central",
          staffName: "Emma Johnson",
          email: "emma@example.com",
          role: "Cashier",
          status: "Inactive",
        },
        {
          id: 7,
          restaurantName: "Wendy's",
          branch: "Old Town",
          staffName: "Lucas Lee",
          email: "lucas@example.com",
          role: "Manager",
          status: "Active",
        },
        {
          id: 8,
          restaurantName: "Chipotle",
          branch: "Lakeside",
          staffName: "Ava Harris",
          email: "ava@example.com",
          role: "Assistant Manager",
          status: "Active",
        },
        {
          id: 9,
          restaurantName: "Panda Express",
          branch: "Airport",
          staffName: "Sophia Clark",
          email: "sophia@example.com",
          role: "Chef",
          status: "Inactive",
        },
        {
          id: 10,
          restaurantName: "Starbucks",
          branch: "Mall",
          staffName: "Liam Martinez",
          email: "liam@example.com",
          role: "Barista",
          status: "Active",
        },
      ],

      pagination: {
        page: 1,
        limit: 12,
        totalPages: 1,
        totalCount: 10,
      },
    });
  };

  changeTimeFilter = (timeFilter: number) => {
    this.setState(
      {
        timeFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminUsers();
      }
    );
  };

  changeRoleFilter = (roleFilter: string | number) => {
    this.setState(
      {
        roleFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminUsers();
      }
    );
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  clearRequestNameSearch = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }

    this.setState(
      {
        requestNameSearch: "",
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminUsers();
      }
    );
  };

  handleSearch = (event: any) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    const target = event.target as HTMLInputElement;

    this.setState(
      {
        requestNameSearch: target.value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminUsers();
      }
    );
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchAdminUsers();
      }
    );
  };

  addAdminUser = (values: any) => {};

  editAdminUser = (values: any) => {};

  openAdminUserManagementDialog = (mode: AdminUserManagementAction) => {
    this.setState({
      adminUserManagementDialog: {
        ...this.state.adminUserManagementDialog,
        mode,
        open: true,
      },
    });
  };

  closeAdminUserManagementDialog = () => {
    this.setState({
      adminUserManagementDialog: {
        mode: AdminUserManagementAction.Add,
        open: false,
        loading: false,

        form: {
          id: "",
          restaurantName: "",
          branch: "",
          staffName: "",
          email: "",
          role: "",
          status: "",
        },

        roleOptions: [],

        branchOptions: [],

        statusOptions: [],
      },
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}
