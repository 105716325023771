import React from "react";
// Customizable Area Start
import {
  Box,
  IconButton,
  InputAdornment,
  Portal,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core";
import CustomInputField from "../../../components/src/CustomInputField.web";
import CustomTable from "../../../components/src/CustomTable";
import KitchenAccountManagementController, {
  Props,
} from "./KitchenAccountManagementController.web";
import moment from "moment";
import { AddCircleOutline, Close, Search } from "@material-ui/icons";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomFilter from "../../../components/src/CustomFilter.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import KitchenAccountFormDrawer from "../../../components/src/KitchenAccountFormDrawer.web";
import KitchenAccountManagementMenu from "../../../components/src/KitchenAccountManagementMenu.web";
import KitchenAccountDeleteDialog from "../../../components/src/KitchenAccountDeleteDialog.web";
import { withTranslation } from "react-i18next";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "&.rtl": {
        direction: "rtl",
      },
    },

    actions: {
      display: "flex",
      gap: 10,
      alignItems: "center",

      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },

      [theme.breakpoints.up("md")]: {
        justifyContent: "end",
      },
    },

    addButton: {
      [theme.breakpoints.down("xs")]: {
        order: 1,
        marginLeft: "auto",
        width: "100% !important",
      },
    },

    search: {
      [theme.breakpoints.down("xs")]: {
        order: 2,
        flex: 1,
      },
    },

    filter: {
      [theme.breakpoints.down("xs")]: {
        order: 3,
      },
    },

    userData: {
      marginTop: 24,
    },

    userTable: {
      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },
    },

    userTableId: {
      fontFamily: "Inter !important",
    },

    userTableTime: {
      fontFamily: "Inter !important",
    },

    pagination: {
      display: "flex",
      justifyContent: "end",
      marginTop: 24,
    },
  });
// Customizable Area End

export class KitchenAccountManagement extends KitchenAccountManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, t, i18n } = this.props;

    const {
      users,
      filter,
      loading,
      pagination,
      snackbar,
      filterMenuList,
      accountDrawer,
      loadingAddAccount,
      deleteUserDialog,
      search,
    } = this.state;

    const rtl = i18n.language === "ar";

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <CustomLoadingBackdrop open={loading} />

        <Box className={classes.actions}>
          <CustomInputField
            className={classes.search}
            variant="outlined"
            placeholder={t("Search")}
            inputRef={this.inputRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {search && (
                    <IconButton
                      data-test-id="clear-search-order-id-btn"
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={this.clearSearch}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  )}
                </>
              ),
            }}
            onKeyDown={this.handleSearch}
          />

          <CustomFilter
            label={t("Filter")}
            className={classes.filter}
            value={filter}
            onChange={this.changeFilter}
            menu={filterMenuList}
          />

          <CustomButton
            className={classes.addButton}
            startIcon={<AddCircleOutline />}
            variant="contained"
            color="primary"
            onClick={this.openAccountDrawer}
          >
            {t("Add User")}
          </CustomButton>
        </Box>

        <Box className={classes.userData}>
          <TableContainer>
            <CustomTable className={classes.userTable}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("User ID")}</TableCell>
                  <TableCell>{t("Date & Time")}</TableCell>
                  <TableCell>{t("Username")}</TableCell>
                  <TableCell>{t("Email Address")}</TableCell>
                  <TableCell>{t("User Role")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {users.length ? (
                  users.map((user) => (
                    <TableRow
                      key={user.id}
                      data-test-id={`table-row-${user.id}`}
                    >
                      <TableCell className={classes.userTableId}>
                        {user.id}
                      </TableCell>
                      <TableCell className={classes.userTableTime}>
                        {moment(user.time).format("DD MMM YYYY hh:mmA")}
                      </TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{t(user.role)}</TableCell>
                      <TableCell>
                        <KitchenAccountManagementMenu
                          data-test-id="action-menu"
                          onSelectAction={(action) =>
                            this.handleSelectAction(user.id, action)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div style={{ textAlign: "center" }}>{t("No users")}</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.pagination}>
            <CustomPagination
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(event, page) => this.changePage(page)}
              showOffset
            />
          </Box>
        </Box>

        {/* Portal helps snackbar won't be overlaid by backdrop */}

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>

        <KitchenAccountFormDrawer
          data-test-id="kitchen-account-form-drawer"
          open={accountDrawer}
          loading={loadingAddAccount}
          onClose={this.closeAccountDrawer}
          onSubmit={this.addNewUser}
          userRoles={filterMenuList}
        />

        <KitchenAccountDeleteDialog
          data-test-id="kitchen-account-delete-dialog"
          dialog={deleteUserDialog}
          onClose={this.closeDeleteUserDialog}
          onDelete={this.deleteUser}
        />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(withTranslation()(KitchenAccountManagement));
// Customizable Area End
