// Customizable Area Start
import React from "react";

import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

import PromocodesAdvertisingController, {
  Props,
} from "./PromocodesAdvertisingController.web";

import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomCheckBox from "../../../components/src/CustomFormCheckBox.web";
import CustomPromocodesAdvertisingCreateAccordionWeb from "../../../components/src/promocodes/CustomPromocodesAdvertisingCreateAccordion.web";

import { CheckRounded, MoreVert } from "@material-ui/icons";

import { iconBoost, iconCheckPerf, iconPay, iconView } from "./assets";
import {
  Divider,
  FormControlLabel,
  IconButton,
  RadioGroup,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CustomRadio from "../../../components/src/CustomRadio.web";
import { toRem } from "../../../components/src/Utils.web";
import CustomTable from "../../../components/src/CustomTable";
import CustomSwitchWithTextWeb from "../../../components/src/CustomSwitchWithText.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomSnackbarWeb from "../../../components/src/CustomSnackbar.web";
import CustomPromocodesAdvertisingCreateWeb from "../../../components/src/promocodes/CustomPromocodesAdvertisingCreate.web";
import CustomLoadingBackdropWeb from "../../../components/src/CustomLoadingBackdrop.web";
import moment from "moment";
import { withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "flex-start",
      gap: 8,
      flexWrap: "wrap",
      marginBlockStart: 8,
      "& .header__title": {
        fontSize: toRem(22),
        lineHeight: 1.17,
        fontWeight: 600,
      },
      "& .header__description": {
        fontWeight: 400,
        marginTop: 8,
        whiteSpace: "pre",
      },
      "& .header__button": {
        [theme.breakpoints.up("lg")]: {
          marginInlineStart: "auto",
        },
      },
    },
    feature: {
      marginTop: 10,
      display: "flex",
      gap: 15,
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
      "& > *": {
        flex: 1,
      },
      "& .feature__item": {
        "&[data-feature-id='0']": {
          "--color-bg": "#FF6666",
        },
        "&[data-feature-id='1']": {
          "--color-bg": "#FFDD99",
          "--color-text": "#27567B",
        },
        "&[data-feature-id='2']": {
          "--color-bg": "#27A6EC",
        },
        borderRadius: 8,
        padding: 12,
        display: "flex",
        flexDirection: "column",
        gap: 10,
        backgroundColor: "var(--color-bg)",
        color: "var(--color-text, #FFF)",

        "& .feature__item__title": {
          fontSize: toRem(16),
          fontWeight: 600,
          color: "inherit",
        },
        "& .feature__item__description": {
          fontSize: toRem(12),
          color: "inherit",
        },
      },
    },
    formCreateCampaign: {
      "& .formCreateCampaign__divider": {
        backgroundColor: "#FFB946",
        margin: 0,
        width: "100%",
      },
      "& .formCreateCampaign__text_bold": {
        fontWeight: 700,
        letterSpacing: 0.04,
      },
      "& p:has(.formCreateCampaign__buttonEdit)": {
        display: "inline-flex",
        alignItems: "baseline",
        gap: 4,
        width: "100%",
        "& .formCreateCampaign__buttonEdit": {
          marginInlineStart: "auto",
        },
      },
      "& .formCreateCampaign__radio__text": {
        fontSize: toRem(14),
        lineHeight: 24 / 14,
        fontFamily: "Inter",
        fontWeight: 700,
        "& span.rcm": {
          fontWeight: 600,
          color: "#FF6666",
          paddingInlineStart: 8,
        },
      },
      "& .formCreateCampaign__note": {
        fontSize: toRem(16),
        "& span": {
          color: "#FF6666",
          fontWeight: 600,
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
    },
    main: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      columnGap: 14,
      marginTop: 15,

      "& .main__box": {
        backgroundColor: "#FFFBF4",
        padding: "12px",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 10,
      },
      "& .main__box + .main__box": {
        marginBlockStart: 10,
      },
    },
    faq: {
      "& .faq__title": {
        fontSize: toRem(22),
        lineHeight: 25.83 / 22,
        fontWeight: 600,
        textAlign: "end",
      },
    },
    content: {
      marginBlockStart: 24,
      "& .content__title": {
        fontWeight: 600,
        fontSize: 22,
      },
    },

    pagination: {
      display: "flex",
      justifyContent: "end",
      marginBlockStart: 24,
    },

    table: {
      marginBlockStart: 15,
      "& .table__cell__number": {
        fontFamily: "Inter",
        "&.table__cell__number_bold": {
          fontWeight: 600,
        },
      },
      "& .table__cell__runningStatus": {
        minWidth: 120,
      },
    },
  });

export class PromocodesAdvertising extends PromocodesAdvertisingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;
    const {
      formCheck,
      pagination,
      snackbar,
      showCreateScreen,
      showTable,
      loading,
      faqs,
      campaigns,
      monthlyBudget,
      monthlyBudgetSelectedId,
      monthlyBudgetMap,
    } = this.state;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    if (showCreateScreen) {
      return (
        <CustomPromocodesAdvertisingCreateWeb
          monthlyBudget={monthlyBudget}
          monthlyBudgetMap={monthlyBudgetMap}
          onCancel={this.hideCreateScreen}
          onSubmit={(values) => {
            const start = moment(values.start).format("YYYY-MM-DD");
            const end = moment(values.end).format("YYYY-MM-DD");

            const payload = {
              advertisement: {
                campaign_name: values.name,
                campaign_description: values.description,
                monthly_budget_id: values.budget,
                start_date: start,
                end_date: end,
                terms_and_conditions: true,
                privacy_policies: true,
              },
            };

            this.createCampaign(payload);
          }}
        />
      );
    }

    return (
      <>
        <CustomLoadingBackdropWeb open={loading} />

        <div className={classes.header} dir={dir}>
          <div>
            <CustomTypography className="header__title">
              {t("Create a new Premium Position campaign")}
            </CustomTypography>
            <CustomTypography className="header__description" variant="h5">
              {t(
                "Elevate Your Experience: Access Exclusive Perks with Our Premium Position Campaign! Don't Miss Out,\nUpgrade Today!"
              )}
            </CustomTypography>
          </div>

          <CustomButton
            className="header__button"
            variant="contained"
            color="primary"
            startIcon={<img src={iconView} />}
            onClick={this.showCreateScreen}
            data-testid="buttonCreateCampaign"
            dir={dir}
          >
            {t("Create Campaign")}
          </CustomButton>
        </div>

        <div className={classes.feature} dir={dir}>
          {FEATURES.map((feature, featureId) => {
            return (
              <div
                className="feature__item"
                key={featureId}
                data-feature-id={featureId}
                dir={dir}
              >
                <img src={feature.icon} alt="" width={"54"} />
                <CustomTypography className="feature__item__title">
                  {t(feature.title)}
                </CustomTypography>
                <CustomTypography className="feature__item__description">
                  {t(feature.description)}
                </CustomTypography>
              </div>
            );
          })}
        </div>

        {showTable ? (
          <div className={classes.content} dir={dir}>
            <CustomTypography className="content__title">
              {t("All Campaign")}
            </CustomTypography>
            <TableContainer>
              <CustomTable className={classes.table} dir={dir}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Campaign")}</TableCell>
                    <TableCell>{t("Start")}</TableCell>
                    <TableCell>{t("End")}</TableCell>
                    <TableCell>{t("Engagement")}</TableCell>
                    <TableCell>{t("Revenue")}</TableCell>
                    <TableCell className="table__cell__runningStatus">
                      {t("Running Status")}
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {campaigns.map((campaign) => (
                    <TableRow key={campaign.id}>
                      <TableCell>{campaign.attributes.campaign_name}</TableCell>
                      <TableCell className="table__cell__number">
                        {this.formatCampaignDate(
                          campaign.attributes.start_date
                        )}
                      </TableCell>
                      <TableCell className="table__cell__number">
                        {this.formatCampaignDate(campaign.attributes.end_date)}
                      </TableCell>
                      <TableCell className="table__cell__number table__cell__number_bold">
                        {t("{{engagement_view_rate}} view", {
                          engagement_view_rate:
                            campaign.attributes.engagement_view_rate,
                        })}
                      </TableCell>
                      <TableCell className="table__cell__number table__cell__number_bold">
                        {t("$")} {campaign.attributes.revenue}
                      </TableCell>
                      <TableCell className="table__cell__runningStatus">
                        <CustomSwitchWithTextWeb
                          checkedText={t("Active")}
                          uncheckedText={t("Inactive")}
                          checked={campaign.attributes.running_status}
                          onChange={() => {
                            this.updateCampaign(
                              campaign.id,
                              !campaign.attributes.running_status
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton>
                          <MoreVert />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </CustomTable>
            </TableContainer>

            <div className={classes.pagination} dir={dir}>
              <CustomPagination
                data-testid="pagination"
                variant="text"
                count={pagination.totalPages}
                page={pagination.page}
                total={pagination.totalCount}
                limit={pagination.limit}
                onChange={(event, page) => this.changePage(page)}
                showOffset
              />
            </div>
          </div>
        ) : (
          <div className={classes.main} dir={dir}>
            <div className={classes.formCreateCampaign} dir={dir}>
              <div className="main__box">
                <CustomTypography className="formCreateCampaign__text_bold">
                  {t("Target Areas")}
                </CustomTypography>
                <CustomTypography>
                  {t(
                    "Your ad will target customers in all the promotional areas."
                  )}
                </CustomTypography>

                <Divider className="formCreateCampaign__divider" />

                <CustomTypography className="formCreateCampaign__text_bold">
                  {t("Monthly Budget")}
                </CustomTypography>
                <CustomTypography>
                  {t("Auto-refreshes on the 1st of each month")}
                </CustomTypography>

                <RadioGroup
                  data-testid="radioGroupBudget"
                  name="budget"
                  value={monthlyBudgetSelectedId}
                  onChange={(event, value) => {
                    this.handleRadioChange(value);
                  }}
                  dir={dir}
                >
                  {monthlyBudget.map((budget, index) => {
                    return (
                      <FormControlLabel
                        key={budget.id}
                        value={budget.id}
                        control={<CustomRadio />}
                        checked={budget.id === monthlyBudgetSelectedId}
                        label={
                          <span className="formCreateCampaign__radio__text">
                            {budget.attributes.budget_value} {t("SAR")}{" "}
                            {budget.attributes.recommended && (
                              <span className="rcm">{t("Recommended")}</span>
                            )}
                          </span>
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </div>

              <div className="main__box" dir={dir}>
                <CustomTypography className="formCreateCampaign__text_bold">
                  {t("Estimated Performance")}
                </CustomTypography>
                <CustomTypography>
                  {t("Up to")}{" "}
                  <span className="formCreateCampaign__text_bold">
                    {this.getViewOfMonthlyBudget(monthlyBudgetSelectedId)}{" "}
                    {t("menu Views (Clicks)")}
                  </span>{" "}
                  {t("per month")}
                </CustomTypography>

                <Divider className="formCreateCampaign__divider" />

                <CustomCheckBox
                  name="tncAndPivacyPolicy"
                  checked={formCheck}
                  label={
                    <span className="formCreateCampaign__note">
                      {t("I am agree with")}{" "}
                      <span>{t("terms & conditions")}</span> {t("and")}{" "}
                      <span>{t("privacy policy")}</span>
                    </span>
                  }
                  onChange={(event, value) => {
                    this.handleChecboxChange(value);
                  }}
                  dir={dir}
                />
                <CustomButton
                  variant="contained"
                  color="primary"
                  startIcon={<CheckRounded />}
                  className="formCreateCampaign__buttonSubmit"
                  onClick={this.showTable}
                  data-testid="buttonStartCampaign"
                  dir={dir}
                >
                  {t("Start Campaign")}
                </CustomButton>
              </div>
            </div>

            <div className={classes.faq} dir={dir}>
              <CustomTypography className="faq__title">
                {t("FAQ")}
              </CustomTypography>
              {faqs.map((faq) => {
                return (
                  <CustomPromocodesAdvertisingCreateAccordionWeb
                    key={faq.id}
                    title={faq.attributes.question}
                    description={faq.attributes.answer}
                    dir={dir}
                  />
                );
              })}
            </div>
          </div>
        )}

        <CustomSnackbarWeb
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.open}
          type={snackbar.type}
          message={snackbar.message}
          onClose={this.closeSnackbar}
          dir={dir}
        />

        <button hidden data-testid="buttonHidden" onClick={this.reset}>
          buttonHidden
        </button>
      </>
    );
  }
}

const FEATURES = [
  {
    title: "Boost your ranking",
    description:
      "Increase your visibility to gain more menu views & increase your orders.",
    icon: iconBoost,
  },
  {
    title: "Pay only for Menu Views",
    description:
      "You only pay based on the clicks we deliver, never more than your budget.",
    icon: iconPay,
  },
  {
    title: "Check Performance",
    description:
      "Detailed reporting keeps you up to date and provides valuable insights.",
    icon: iconCheckPerf,
  },
];

export default withStyles(styles)(withTranslation()(PromocodesAdvertising));
// Customizable Area End
